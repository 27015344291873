// src/components/Navbar.jsx

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../config/AuthProvider';
import '../css/Navbar.css'; // Provjerite putanju prema vašem CSS fajlu

function Navbar() {
  const { currentUser, logout } = useContext(AuthContext);

  const handleLogout = async () => {
    try {
      await logout();
      // Opcionalno: preusmjerite korisnika nakon odjave
    } catch (error) {
      console.error('Greška pri odjavi:', error);
      alert('Došlo je do greške prilikom odjave. Pokušajte ponovno.');
    }
  };

  return (
    <nav className="navbar">
      <ul>
        <li>
          <Link to="/">Obračun</Link>
        </li>
        <li>
          <Link to="/metrike">Metrike</Link>
        </li>
        {currentUser && (
          <li className="logout">
            <button onClick={handleLogout}>Odjava</button>
          </li>
        )}
      </ul>
    </nav>
  );
}

export default Navbar;