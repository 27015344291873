// PersonTable.jsx

import React, { useMemo } from 'react';
import ExcelUploader from './ExcelUploader';
import PersonRow from './PersonRow';

function PersonTable({
  people = [],
  earningsData = [],
  userPeriodData = {},
  selectedNationality,
  updateEarnings,
  selectedPeriod,
  selectedYear,
  selectedMonth,
  onDeletePerson,
  onUpdatePerson,
  refreshData,
  firms = [],
  selectedFirm = '',
}) {
  // Determine the number of weeks and starting week
  let numOfWeeks;
  let startWeek;

  if (selectedNationality === 'Hrvat') {
    if (selectedPeriod === 1) {
      numOfWeeks = 2; // Weeks 1 and 2
      startWeek = 1;
    } else if (selectedPeriod === 2) {
      numOfWeeks = 3; // Weeks 3, 4, and 5
      startWeek = 3;
    }
  } else {
    numOfWeeks = 5;
    startWeek = 1;
  }

  // Filter people based on selected nationality and firm
  const filteredPeople = useMemo(() => {
    return people.filter((person) => {
      const nationalityMatch =
        selectedNationality === 'Sve' || person.nationality === selectedNationality;
      const firmMatch = selectedFirm === '' || person.firmId === selectedFirm;
      return nationalityMatch && firmMatch;
    });
  }, [people, selectedNationality, selectedFirm]);

  // Function to calculate sums
  const sums = useMemo(() => {
    const sums = {
      // Earnings per week
      ...Array.from({ length: numOfWeeks }, (_, i) => [`tjedan${startWeek + i}`, 0]).reduce(
        (acc, [key, value]) => {
          acc[key] = value;
          return acc;
        },
        {}
      ),
      // Other sums
      zaradaTotal: 0,
      feeIznos: 0,
      total1: 0,
      appKnjig: 0,
      doprinosi: 0,
      renta: 0,
      rentSmjestaj: 0,
      oprema: 0,
      akontacija: 0,
      gorivo: 0,
      ostalo: 0,
      bonus: 0,
      total2: 0,
      tarifa: 0, // Added
      depozit: 0, // Added
      struja: 0, // Added
      glovoTax: 0, // Added
    };

    filteredPeople.forEach((person) => {
      // Filter earnings for this person
      const personEarnings = earningsData.filter((earning) => earning.userId === person.id);

      // Sum earnings per week
      personEarnings.forEach((earning) => {
        const weekNumber = earning.week;
        const weekKey = `tjedan${weekNumber}`;
        if (sums.hasOwnProperty(weekKey)) {
          sums[weekKey] += parseFloat(earning.amount) || 0;
        }
      });

      // Fetch financial data from userPeriodData or use defaults from person
      const personData = userPeriodData[person.id] || {
        fee: person.fee || 0,
        appKnjig: person.appKnjig || 0,
        doprinosi: person.doprinosi || 0,
        renta: 0,
        rentSmjestaj: 0,
        oprema: 0,
        akontacija: 0,
        gorivo: 0,
        ostalo: 0,
        bonus: 0,
      };

      

      // Calculate zaradaTotal for this person
      const zaradaTotal = personEarnings.reduce(
        (sum, earning) => sum + (parseFloat(earning.amount) || 0),
        0
      );

      // Calculate fee amount
      const feeIznos = (zaradaTotal * (personData.fee || 0)) / 100;

      // Calculate total1
      const total1 = zaradaTotal - feeIznos;

      // Calculate total2
      const total2 =
        total1 -
        (parseFloat(personData.appKnjig) || 0) -
        (parseFloat(personData.doprinosi) || 0) -
        (parseFloat(personData.renta) || 0) -
        (parseFloat(personData.rentSmjestaj) || 0) -
        (parseFloat(personData.oprema) || 0) -
        (parseFloat(personData.akontacija) || 0) -
        (parseFloat(personData.gorivo) || 0) -
        (parseFloat(personData.ostalo) || 0) +
        (parseFloat(personData.bonus) || 0);

      // Sum other fields
      sums.zaradaTotal += zaradaTotal;
      sums.feeIznos += feeIznos;
      sums.total1 += total1;
      sums.appKnjig += parseFloat(personData.appKnjig) || 0;
      sums.doprinosi += parseFloat(personData.doprinosi) || 0;
      sums.renta += parseFloat(personData.renta) || 0;
      sums.rentSmjestaj += parseFloat(personData.rentSmjestaj) || 0;
      sums.oprema += parseFloat(personData.oprema) || 0;
      sums.akontacija += parseFloat(personData.akontacija) || 0;
      sums.gorivo += parseFloat(personData.gorivo) || 0;
      sums.ostalo += parseFloat(personData.ostalo) || 0;
      sums.bonus += parseFloat(personData.bonus) || 0;
      sums.total2 += total2;
      sums.tarifa += parseFloat(personData.tarifa) || 0;
      sums.depozit += parseFloat(personData.depozit) || 0;
      sums.struja += parseFloat(personData.struja) || 0;
      sums.glovoTax += parseFloat(personData.glovoTax) || 0;

      
    });

    return sums;
  }, [filteredPeople, earningsData, userPeriodData, numOfWeeks, startWeek]);

  return (
    <table>
      <thead>
        <tr>
          <th>Ime</th>
          <th>Uređivanje</th>
          <th>Rider ID</th>
          <th>Firma</th>
          <th>N</th>
          {[...Array(numOfWeeks)].map((_, i) => (
            <th key={i}>
              <ExcelUploader
                onDataParsed={(data, filename) => {
                  const weekNumber = startWeek + i;
                  console.log(
                    `Uploading data for week ${weekNumber} with filename ${filename}`
                  );
                  updateEarnings(weekNumber, data, filename);
                }}
              >
                Tjedan {startWeek + i}
              </ExcelUploader>
            </th>
          ))}
          <th>Zarada Total</th>
          <th className="highlighted-header">Fee %</th>
          <th className="highlighted-header">FEE Iznos</th>
          <th className="highlighted-header">Total 1</th>
          <th>App Knjig.</th>
          <th>Doprinosi</th>
          <th>Renta</th>
          <th>Rent smještaj</th>
          
            
            <>
              <th>Tarifa</th>
              <th>Depozit</th>
              <th>Struja</th>
              <th>Glovo Tax</th>
            </>
          
          <th>Oprema</th>
          <th>Akontacija</th>
          <th>Gorivo</th>
          <th>Ostalo</th>
          <th>Bonus</th>
          
          <th className="highlighted-header">Total 2</th>
          
        </tr>

        {/* Summary row appears here */}
        {console.log(sums)}
        {selectedNationality === 'Sve' && (
          <tr className="summary-row">
            <td>
              <strong>Suma</strong>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            {[...Array(numOfWeeks)].map((_, i) => {
              const weekKey = `tjedan${startWeek + i}`;
              return (
                <td key={i}>
                  <strong>{sums[weekKey].toFixed(2)}</strong>
                </td>
              );
            })}
            <td>
              <strong>{sums.zaradaTotal.toFixed(2)}</strong>
            </td>
            <td></td>
            <td>
              <strong>{sums.feeIznos.toFixed(2)}</strong>
            </td>
            <td>
              <strong>{sums.total1.toFixed(2)}</strong>
            </td>
            <td>
              <strong>{sums.appKnjig.toFixed(2)}</strong>
            </td>
            <td>
              <strong>{sums.doprinosi.toFixed(2)}</strong>
            </td>
            <td>
              <strong>{sums.renta.toFixed(2)}</strong>
            </td>
            <td>
              <strong>{sums.rentSmjestaj.toFixed(2)}</strong>
            </td>
            <td><strong>{sums.tarifa.toFixed(2)}</strong></td> 
            <td><strong>{sums.depozit.toFixed(2)}</strong></td> 
            <td><strong>{sums.struja.toFixed(2)}</strong></td> 
            <td><strong>{sums.glovoTax.toFixed(2)}</strong></td> 
            <td>
              <strong>{sums.oprema.toFixed(2)}</strong>
            </td>
            <td>
              <strong>{sums.akontacija.toFixed(2)}</strong>
            </td>
            <td>
              <strong>{sums.gorivo.toFixed(2)}</strong>
            </td>
            <td>
              <strong>{sums.ostalo.toFixed(2)}</strong>
            </td>
            <td>
              <strong>{sums.bonus.toFixed(2)}</strong>
            </td>
            <td>
              <strong>{sums.total2.toFixed(2)}</strong>
            </td>
          </tr>
        )}
      </thead>
      <tbody>
        {filteredPeople.map((person) => (
          <PersonRow
            key={person.id}
            person={person}
            earningsData={earningsData}
            numOfWeeks={numOfWeeks}
            startWeek={startWeek}
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            selectedPeriod={selectedPeriod}
            userPeriodData={userPeriodData[person.id] || {}}
            onDeletePerson={onDeletePerson}
            onUpdatePerson={onUpdatePerson}
            refreshData={refreshData}
            firms={firms}
            selectedNationality={selectedNationality}
          />
        ))}
      </tbody>
    </table>
  );
}

export default PersonTable;
