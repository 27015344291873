// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Metrike from './pages/Metrike';
import Login from './authPage/Login';
import Navbar from './components/Navbar';
import ProtectedRoute from './config/ProtectedRoute';
import { AuthProvider } from './config/AuthProvider';
import Modal from 'react-modal';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import './index.css'; // Vaš globalni CSS

Modal.setAppElement('#root'); // Potrebno za react-modal

const Root = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route 
          path="/" 
          element={
            <ProtectedRoute>
              <App />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/metrike" 
          element={
            <ProtectedRoute>
              <Metrike />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/login" 
          element={<Login />} 
        />
        <Route 
          path="*" 
          element={<Navigate to="/" replace />} 
        />
      </Routes>
    </Router>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <Root />
    </AuthProvider>
  </React.StrictMode>
);