import React, { useState, useEffect } from 'react';
import { db } from '../config/firebase';
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from 'firebase/firestore';
import Modal from 'react-modal';

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ExcelJS from 'exceljs';

import { saveAs } from 'file-saver'; // Ensure you have this installed
import {FaDownload, FaEdit, FaTrash } from 'react-icons/fa'; // Uvozimo ikone
import ReportModal from './ReportModal'; // Import the modal component
import '../css/PersonRow.css'; // Stilovi za komponentu

function PersonRow({
  person,
  earningsData,
  numOfWeeks,
  startWeek,
  selectedYear,
  selectedMonth,
  selectedPeriod,
  userPeriodData,
  onDeletePerson,
  onUpdatePerson,
  refreshData, // Dodajemo refreshData kao prop
  firms = [],// Dodajemo listu firmi kao prop
  selectedNationality, 
}) {
  // State za modale
  const [isPersonModalOpen, setIsPersonModalOpen] = useState(false);
  const [isFinancialModalOpen, setIsFinancialModalOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);

  // Function to open the report modal
  const openReportModal = () => {
    setIsReportModalOpen(true);
  };

  const closeReportModal = () => {
    setIsReportModalOpen(false);
  };

  // State za formu unutar modala (Person Modal)
  const [name, setName] = useState(person.name);
  const [nationality, setNationality] = useState(person.nationality);
  const [tekuciRacun, setTekuciRacun] = useState(person.tekuciRacun || '');
  const [zasticeniRacun, setZasticeniRacun] = useState(person.zasticeniRacun || '');
  const [riderID, setRiderID] = useState(
    (userPeriodData.riderID || person.riderID || '').trim().toLowerCase()
  ); // Dodajemo Rider ID

  // Dodajemo stanje za firmu
  const [firmId, setFirmId] = useState(person.firmId || 'default');

  // Dodajemo nova polja za defaultne vrijednosti
  const [appKnjigDefault, setAppKnjigDefault] = useState(person.appKnjig || 0);
  const [doprinosiDefault, setDoprinosiDefault] = useState(person.doprinosi || 0);
  const [feeDefault, setFeeDefault] = useState(person.fee || 0);

  const [fee, setFee] = useState(
  userPeriodData.fee !== undefined ? userPeriodData.fee : person.fee || 0
  );
  const [appKnjig, setAppKnjig] = useState(
    userPeriodData.appKnjig !== undefined ? userPeriodData.appKnjig : person.appKnjig || 0
  );
  const [doprinosi, setDoprinosi] = useState(
    userPeriodData.doprinosi !== undefined ? userPeriodData.doprinosi : person.doprinosi || 0
  );
  const [renta, setRenta] = useState(
    userPeriodData.renta !== undefined ? userPeriodData.renta : person.renta || 0
  );
  const [rentSmjestaj, setRentSmjestaj] = useState(
    userPeriodData.rentSmjestaj !== undefined ? userPeriodData.rentSmjestaj : person.rentSmjestaj || 0
  );
  const [oprema, setOprema] = useState(
    userPeriodData.oprema !== undefined ? userPeriodData.oprema : person.oprema || 0
  );
  const [akontacija, setAkontacija] = useState(
    userPeriodData.akontacija !== undefined ? userPeriodData.akontacija : person.akontacija || 0
  );
  const [gorivo, setGorivo] = useState(
    userPeriodData.gorivo !== undefined ? userPeriodData.gorivo : person.gorivo || 0
  );
  const [ostalo, setOstalo] = useState(
    userPeriodData.ostalo !== undefined ? userPeriodData.ostalo : person.ostalo || 0
  );
  const [bonus, setBonus] = useState(
    userPeriodData.bonus !== undefined ? userPeriodData.bonus : person.bonus || 0
  );
  // State variables for financial data
  const [tarifa, setTarifa] = useState(
    userPeriodData.tarifa !== undefined ? userPeriodData.tarifa : person.tarifa || 0
  );
  const [depozit, setDepozit] = useState(
    userPeriodData.depozit !== undefined ? userPeriodData.depozit : person.depozit || 0
  );
  const [struja, setStruja] = useState(
    userPeriodData.struja !== undefined ? userPeriodData.struja : person.struja || 0
  );
  const [glovoTax, setGlovoTax] = useState(
    userPeriodData.glovoTax !== undefined ? userPeriodData.glovoTax : person.glovoTax || 0
  );

  const [napomena, setNapomena] = useState(userPeriodData.napomena || '');

   // Added new default state variables for the person modal
   const [rentaDefault, setRentaDefault] = useState(person.renta || 0);
   const [rentSmjestajDefault, setRentSmjestajDefault] = useState(person.rentSmjestaj || 0);
   const [opremaDefault, setOpremaDefault] = useState(person.oprema || 0);
   const [akontacijaDefault, setAkontacijaDefault] = useState(person.akontacija || 0);
   const [gorivoDefault, setGorivoDefault] = useState(person.gorivo || 0);
   const [ostaloDefault, setOstaloDefault] = useState(person.ostalo || 0);
   const [bonusDefault, setBonusDefault] = useState(person.bonus || 0);
   const [tarifaDefault, setTarifaDefault] = useState(person.tarifa || 0);
   const [depozitDefault, setDepozitDefault] = useState(person.depozit || 0);
   const [strujaDefault, setStrujaDefault] = useState(person.struja || 0);
   const [glovoTaxDefault, setGlovoTaxDefault] = useState(person.glovoTax || 0);

  // State za zarade i izračune
  const [earnings, setEarnings] = useState([]);
  const [feeAmount, setFeeAmount] = useState((0).toFixed(2)); // FEE Iznos

  // useEffect za inicijalizaciju zarada
  useEffect(() => {
    const earningsArray = new Array(numOfWeeks).fill(0);

    earningsData.forEach((earning) => {
      if (earning.userId === person.id) {
        let weekIndex = earning.week - startWeek;
        if (weekIndex >= 0 && weekIndex < numOfWeeks) {
          earningsArray[weekIndex] = earning.amount;
        }
      }
    });

    setEarnings(earningsArray);
  }, [earningsData, person.id, numOfWeeks, startWeek]);

  // Funkcija za izračunavanje zarade total
  const zaradaTotal = earnings.reduce((sum, earning) => sum + earning, 0);

  // Izračun FEE Iznosa
  const calculatedFeeAmount = ((zaradaTotal * fee) / 100).toFixed(2);

  // Izračun Total 1
  const total1 = (zaradaTotal - (zaradaTotal * fee) / 100).toFixed(2);

  // Izračun Total 2
  const total2 = (
    zaradaTotal -
    (zaradaTotal * fee) / 100 -
    appKnjig -
    doprinosi -
    renta -
    rentSmjestaj -
    oprema -
    akontacija -
    gorivo -
    ostalo -
    (person.nationality === 'Stranac' ? tarifa + depozit + struja + glovoTax : 0) +
    bonus
  ).toFixed(2);

  // Ažuriranje stanja kada se promijene props
  useEffect(() => {
    setName(person.name);
    setNationality(person.nationality);
    setTekuciRacun(person.tekuciRacun || '');
    setZasticeniRacun(person.zasticeniRacun || '');
    setRiderID((userPeriodData.riderID || person.riderID || '').trim().toLowerCase());
    setFirmId(person.firmId || 'default');

    setRentaDefault(person.renta || 0);
    setRentSmjestajDefault(person.rentSmjestaj || 0);
    setOpremaDefault(person.oprema || 0);
    setAkontacijaDefault(person.akontacija || 0);
    setGorivoDefault(person.gorivo || 0);
    setOstaloDefault(person.ostalo || 0);
    setBonusDefault(person.bonus || 0);

    // Ažuriramo nova polja
    setAppKnjigDefault(person.appKnjig || 0);
    setDoprinosiDefault(person.doprinosi || 0);
    setFeeDefault(person.fee || 0);

    if (person.nationality === 'Stranac') {
      setTarifaDefault(person.tarifa || 0);
      setDepozitDefault(person.depozit || 0);
      setStrujaDefault(person.struja || 0);
      setGlovoTaxDefault(person.glovoTax || 0);
    }

  }, [person, userPeriodData]);

  // Ažuriramo financijska polja kada se promijeni userPeriodData ili person
  useEffect(() => {
    setFee(userPeriodData.fee !== undefined ? userPeriodData.fee : person.fee || 0);
    setAppKnjig(userPeriodData.appKnjig !== undefined ? userPeriodData.appKnjig : person.appKnjig || 0);
    setDoprinosi(userPeriodData.doprinosi !== undefined ? userPeriodData.doprinosi : person.doprinosi || 0);
    setRenta(userPeriodData.renta !== undefined ? userPeriodData.renta : person.renta || 0);
    setRentSmjestaj(userPeriodData.rentSmjestaj !== undefined ? userPeriodData.rentSmjestaj : person.rentSmjestaj || 0);
    setOprema(userPeriodData.oprema !== undefined ? userPeriodData.oprema : person.oprema || 0);
    setAkontacija(userPeriodData.akontacija !== undefined ? userPeriodData.akontacija : person.akontacija || 0);
    // Corrected typo from 'gorvio' to 'gorivo'
    setGorivo(userPeriodData.gorivo !== undefined ? userPeriodData.gorivo : person.gorivo || 0);
    setOstalo(userPeriodData.ostalo !== undefined ? userPeriodData.ostalo : person.ostalo || 0);
    setBonus(userPeriodData.bonus !== undefined ? userPeriodData.bonus : person.bonus || 0);
    // Corrected typo from 'nampomena' to 'napomena'
    setNapomena(userPeriodData.napomena !== undefined ? userPeriodData.napomena : person.napomena || '');
    if (person.nationality === 'Stranac') {
      setTarifa(userPeriodData.tarifa !== undefined ? userPeriodData.tarifa : person.tarifa || 0);
      setDepozit(userPeriodData.depozit !== undefined ? userPeriodData.depozit : person.depozit || 0);
      setStruja(userPeriodData.struja !== undefined ? userPeriodData.struja : person.struja || 0);
      setGlovoTax(userPeriodData.glovoTax !== undefined ? userPeriodData.glovoTax : person.glovoTax || 0);
    }
  }, [userPeriodData, person]);

  // Update feeAmount kada se promijeni zaradaTotal ili fee
  useEffect(() => {
    setFeeAmount(calculatedFeeAmount);
  }, [zaradaTotal, fee]);

  // Funkcije za otvaranje i zatvaranje modala
  const openPersonModal = () => {
    setIsPersonModalOpen(true);
  };

  const closePersonModal = () => {
    setIsPersonModalOpen(false);
  };

  const openFinancialModal = () => {
    setIsFinancialModalOpen(true);
  };

  const closeFinancialModal = () => {
    setIsFinancialModalOpen(false);
  };

  // Funkcija za spremanje promjena u Person Modal
  const handleSavePerson = async () => {
    try {
      // Normalizacija Rider ID-a
      const normalizedRiderID = riderID.trim().toLowerCase();

      // Provjera jedinstvenosti Rider ID-a ako se mijenja
      if (normalizedRiderID !== (person.riderID || '').trim().toLowerCase()) {
        const riderIDQuery = query(
          collection(db, 'users'),
          where('riderID', '==', normalizedRiderID)
        );
        const riderIDSnapshot = await getDocs(riderIDQuery);
        if (!riderIDSnapshot.empty) {
          alert('Rider ID već postoji. Molimo unesite jedinstveni Rider ID.');
          return;
        }
      }

      const updatedPerson = {
        ...person,
        name,
        nationality,
        tekuciRacun,
        zasticeniRacun,
        riderID: normalizedRiderID,
        firmId,
        appKnjig: appKnjigDefault,
        doprinosi: doprinosiDefault,
        fee: feeDefault,
        renta: rentaDefault,
        rentSmjestaj: rentSmjestajDefault,
        oprema: opremaDefault,
        akontacija: akontacijaDefault,
        gorivo: gorivoDefault,
        ostalo: ostaloDefault,
        bonus: bonusDefault,
      };

      // If nationality is 'Stranac', include additional fields
      if (nationality === 'Stranac') {
        updatedPerson.tarifa = tarifaDefault;
        updatedPerson.depozit = depozitDefault;
        updatedPerson.struja = strujaDefault;
        updatedPerson.glovoTax = glovoTaxDefault;
      } else {
        // Remove the fields if the nationality changed to 'Hrvat'
        delete updatedPerson.tarifa;
        delete updatedPerson.depozit;
        delete updatedPerson.struja;
        delete updatedPerson.glovoTax;
      }

      await updateDoc(doc(db, 'users', person.id), updatedPerson);

      onUpdatePerson(updatedPerson);

      closePersonModal();
      alert('Promjene osnove osobe su uspješno spremljene!');
    } catch (e) {
      console.error('Greška pri ažuriranju korisnika:', e);
      alert('Došlo je do greške prilikom spremanja promjena. Pokušajte ponovno.');
    }
  };

  // Funkcija za spremanje promjena u Financial Modal
  // Function to save financial data
  const handleSaveFinancial = async () => {
    try {
      // Query to find existing 'userPeriodData' document
      const userPeriodQuery = query(
        collection(db, 'userPeriodData'),
        where('userId', '==', person.id),
        where('year', '==', selectedYear),
        where('month', '==', selectedMonth),
        where('period', '==', selectedPeriod)
      );
      const userPeriodSnapshot = await getDocs(userPeriodQuery);

      let docRef;
      const dataToSave = {
        fee,
        appKnjig,
        doprinosi,
        renta,
        rentSmjestaj,
        oprema,
        akontacija,
        gorivo,
        ostalo,
        bonus,
        napomena,
        zaradaTotal: zaradaTotal.toFixed(2),
        feeAmount: feeAmount,
        total1: total1,
        total2: total2,
      };

      // Include foreigner-specific fields if nationality is 'Stranac'
      if (person.nationality === 'Stranac') {
        dataToSave.tarifa = tarifa;
        dataToSave.depozit = depozit;
        dataToSave.struja = struja;
        dataToSave.glovoTax = glovoTax;
      } else {
        // Optionally, you can set these fields to zero or remove them
        dataToSave.tarifa = 0;
        dataToSave.depozit = 0;
        dataToSave.struja = 0;
        dataToSave.glovoTax = 0;
      }

      if (!userPeriodSnapshot.empty) {
        // Update existing document
        docRef = userPeriodSnapshot.docs[0].ref;
        await updateDoc(docRef, dataToSave);
      } else {
        // Create new document
        dataToSave.userId = person.id;
        dataToSave.year = selectedYear;
        dataToSave.month = selectedMonth;
        dataToSave.period = selectedPeriod;
        await addDoc(collection(db, 'userPeriodData'), dataToSave);
      }

      // Save weekly earnings
      for (let i = 0; i < earnings.length; i++) {
        const weekNumber = startWeek + i;
        const amount = earnings[i];

        // Query for existing earnings document
        const earningsQuery = query(
          collection(db, 'earnings'),
          where('userId', '==', person.id),
          where('year', '==', selectedYear),
          where('month', '==', selectedMonth),
          where('week', '==', weekNumber),
          where('firmId', '==', person.firmId)
        );

        const earningsSnapshot = await getDocs(earningsQuery);

        if (!earningsSnapshot.empty) {
          // Update existing document
          const earningsDocRef = earningsSnapshot.docs[0].ref;
          await updateDoc(earningsDocRef, {
            amount: amount,
            period: selectedPeriod,
          });
        } else {
          // Create new earnings document
          await addDoc(collection(db, 'earnings'), {
            userId: person.id,
            year: selectedYear,
            month: selectedMonth,
            week: weekNumber,
            amount: amount,
            firmId: person.firmId,
            period: selectedPeriod,
          });
        }
      }

      // Refresh data
      refreshData();

      closeFinancialModal();
      alert('Financijske promjene su uspješno spremljene!');
    } catch (e) {
      console.error('Greška pri ažuriranju financijskih podataka:', e);
      alert('Došlo je do greške prilikom spremanja financijskih promjena. Pokušajte ponovno.');
    }
  };


  // Funkcija za brisanje financijskih podataka (resetiranje na zadane vrijednosti)
  const handleDeleteFinancialData = async () => {
    if (window.confirm('Jeste li sigurni da želite resetirati financijske podatke na zadane vrijednosti?')) {
      try {
        // Dohvaćanje 'userPeriodData' dokumenta
        const userPeriodQuery = query(
          collection(db, 'userPeriodData'),
          where('userId', '==', person.id),
          where('year', '==', selectedYear),
          where('month', '==', selectedMonth),
          where('period', '==', selectedPeriod)
        );
        const userPeriodSnapshot = await getDocs(userPeriodQuery);

        if (!userPeriodSnapshot.empty) {
          // Brisanje dokumenta
          const docRef = userPeriodSnapshot.docs[0].ref;
          await deleteDoc(docRef);

          // Ažuriranje stanja - postavljanje financijskih podataka na defaultne vrijednosti iz 'person' objekta
          setFee(person.fee || 0);
          setAppKnjig(person.appKnjig || 0);
          setDoprinosi(person.doprinosi || 0);
          setRenta(person.renta || 0);
          setRentSmjestaj(person.rentSmjestaj || 0);
          setOprema(person.oprema || 0);
          setAkontacija(person.akontacija || 0);
          setGorivo(person.gorivo || 0);
          setOstalo(person.ostalo || 0);
          setBonus(person.bonus || 0);
          setNapomena('');

          alert('Financijski podaci su resetirani na zadane vrijednosti.');
          refreshData();
        } else {
          alert('Nema financijskih podataka za brisanje.');
        }
      } catch (e) {
        console.error('Greška pri brisanju financijskih podataka:', e);
        alert('Došlo je do greške prilikom brisanja financijskih podataka. Pokušajte ponovno.');
      }
    }
  };

  // Funkcija za brisanje osobe
  const handleDelete = async () => {
    if (window.confirm(`Jeste li sigurni da želite obrisati ${person.name}?`)) {
      try {
        // Brisanje zarada
        const earningsQuery = query(
          collection(db, 'earnings'),
          where('userId', '==', person.id)
        );
        const earningsSnapshot = await getDocs(earningsQuery);
        const deleteEarningsPromises = earningsSnapshot.docs.map((docSnap) =>
          deleteDoc(doc(db, 'earnings', docSnap.id))
        );
        await Promise.all(deleteEarningsPromises);

        // Brisanje 'userPeriodData'
        const periodDataQuery = query(
          collection(db, 'userPeriodData'),
          where('userId', '==', person.id)
        );
        const periodDataSnapshot = await getDocs(periodDataQuery);
        const deletePeriodDataPromises = periodDataSnapshot.docs.map((docSnap) =>
          deleteDoc(doc(db, 'userPeriodData', docSnap.id))
        );
        await Promise.all(deletePeriodDataPromises);

        // Brisanje korisnika
        await deleteDoc(doc(db, 'users', person.id));

        // Ažuriranje roditeljske komponente
        onDeletePerson(person.id);
        closePersonModal();
        closeFinancialModal();
        alert('Korisnik je uspješno izbrisan!');
      } catch (e) {
        console.error('Greška pri brisanju korisnika:', e);
        alert('Došlo je do greške prilikom brisanja korisnika. Pokušajte ponovno.');
      }
    }
  };

  // Funkcija za rukovanje promjenama zarada u Financial Modal
  const handleEarningChange = (e, weekIndex) => {
    const newAmount = parseFloat(e.target.value) || 0;
    const updatedEarnings = [...earnings];
    updatedEarnings[weekIndex] = newAmount;
    setEarnings(updatedEarnings);
  };

  // Funkcija za dohvaćanje imena firme na temelju firmId
  const getFirmName = (firmId) => {
    const firm = firms.find((firm) => firm.id === firmId);
    return firm ? firm.name : 'N/A';
  };

  

  const downloadExcelReport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Report');
  
    // Add headers first, if needed
    worksheet.columns = [
      { header: 'Header', key: 'header', width: 30 },
      { header: 'Value', key: 'value', width: 20 },
    ];
  
    // Prepare the data rows
    const reportData = [
      { header: 'Ime', value: person.name },
      { header: 'Rider ID', value: person.riderID },
      { header: 'Firma', value: getFirmName(person.firmId) },
      { header: 'Nationality', value: person.nationality },
      { header: 'Godina', value: selectedYear },
      { header: 'Mjesec', value: selectedMonth },
      { header: 'Period', value: selectedPeriod },
      ...Array.from({ length: numOfWeeks }, (_, i) => ({
        header: `Tjedan ${startWeek + i}`,
        value: earningsData.find(e => e.userId === person.id && e.week === (startWeek + i))?.amount || 0,
      })),
      { header: 'App Knjig', value: userPeriodData.appKnjig || person.appKnjig || 0 },
      { header: 'Doprinosi', value: userPeriodData.doprinosi || person.doprinosi || 0 },
      { header: 'Renta', value: userPeriodData.renta || person.renta || 0 },
      { header: 'Rent Smještaj', value: userPeriodData.rentSmjestaj || person.rentSmjestaj || 0 },
      { header: 'Oprema', value: userPeriodData.oprema || person.oprema || 0 },
      { header: 'Akontacija', value: userPeriodData.akontacija || person.akontacija || 0 },
      { header: 'Gorivo', value: userPeriodData.gorivo || person.gorivo || 0 },
      { header: 'Ostalo', value: userPeriodData.ostalo || person.ostalo || 0 },
      { header: 'Bonus', value: userPeriodData.bonus || person.bonus || 0 },
      { header: 'Tarifa', value: userPeriodData.tarifa || person.tarifa || 0 },
      { header: 'Struja', value: userPeriodData.struja || person.struja || 0 },
      { header: 'Glovo Tax', value: userPeriodData.glovoTax || person.glovoTax || 0 },
      { header: 'Deposit', value: userPeriodData.deposit || person.deposit || 0 },
    ];
  
    // Add rows to the worksheet
    reportData.forEach(row => {
      worksheet.addRow(row);
    });
  
    // Generate Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `${person.name}_${selectedMonth}_${selectedYear}_P${selectedPeriod}.xlsx`
    );
  };
  
  
  
  
  const downloadPDFReport = () => {
    const doc = new jsPDF('portrait', 'pt', 'A4');
    const margin = 40;
    
    // Define the data as headers and values for the PDF
    const reportData = [
      ['Ime', person.name],
      ['Rider ID', person.riderID],
      ['Firma', getFirmName(person.firmId)],
      ['Nationality', person.nationality],
      ['Godina', selectedYear],
      ['Mjesec', selectedMonth],
      ['Period', selectedPeriod],
      // Add weekly earnings dynamically
      ...Array.from({ length: numOfWeeks }, (_, i) => [
        `Tjedan ${startWeek + i}`,
        earningsData.find(e => e.userId === person.id && e.week === (startWeek + i))?.amount || 0,
      ]),
      ['App Knjig', userPeriodData.appKnjig || person.appKnjig || 0],
      ['Doprinosi', userPeriodData.doprinosi || person.doprinosi || 0],
      ['Renta', userPeriodData.renta || person.renta || 0],
      ['Rent Smještaj', userPeriodData.rentSmjestaj || person.rentSmjestaj || 0],
      ['Oprema', userPeriodData.oprema || person.oprema || 0],
      ['Akontacija', userPeriodData.akontacija || person.akontacija || 0],
      ['Gorivo', userPeriodData.gorivo || person.gorivo || 0],
      ['Ostalo', userPeriodData.ostalo || person.ostalo || 0],
      ['Bonus', userPeriodData.bonus || person.bonus || 0],
      ['Tarifa', userPeriodData.tarifa || person.tarifa || 0],
      ['Struja', userPeriodData.struja || person.struja || 0],
      ['Glovo Tax', userPeriodData.glovoTax || person.glovoTax || 0],
      ['Deposit', userPeriodData.depozit || person.depozit || 0],
    ];
  
    // Add table to PDF using autoTable plugin
    doc.autoTable({
      startY: margin,
      head: [['Header', 'Value']], // Table header
      body: reportData, // Data for the table
      theme: 'grid', // Ensures table borders
      styles: {
        fontSize: 10,
        cellPadding: 5,
        valign: 'middle',
        halign: 'center',
      },
      headStyles: {
        fillColor: [245, 245, 220], // Beige color for header
        textColor: 0,
        fontStyle: 'bold',
      },
    });
  
    // Save the generated PDF
    doc.save(`${person.name}_${selectedMonth}_${selectedYear}_P${selectedPeriod}.pdf`);
  };
  
  const printReport = () => {
    const printWindow = window.open('', '_blank');
    const documentContent = `
      <html>
        <head>
          <style>
            body {
              font-family: Arial, sans-serif;
              padding: 20px; /* Add padding around the content */
            }
            table {
              width: 80%; /* Make the table narrower than the full page */
              margin: 20px auto; /* Center the table horizontally */
              border-collapse: collapse;
              margin-top: 20px;
            }
            th, td {
              border: 1px solid black;
              padding: 12px; /* Increase padding for a better look */
              text-align: center;
            }
            th {
              background-color: #F5F5DC;
              font-weight: bold;
              font-size: 18px; /* Increase font size for header */
            }
            td {
              font-size: 16px; /* Increase font size for table data */
            }
          </style>
        </head>
        <body>
          <h2 style="text-align: center;">${person.name} - Izvještaj</h2>
          <table>
            <tr>
              <th>Header</th>
              <th>Value</th>
            </tr>
            ${[
              ['Ime', person.name],
              ['Rider ID', person.riderID],
              ['Firma', getFirmName(person.firmId)],
              ['Nationality', person.nationality],
              ['Godina', selectedYear],
              ['Mjesec', selectedMonth],
              ['Period', selectedPeriod],
              ...Array.from({ length: numOfWeeks }, (_, i) => [
                `Tjedan ${startWeek + i}`,
                earningsData.find(e => e.userId === person.id && e.week === (startWeek + i))?.amount || 0,
              ]),
              ['App Knjig', userPeriodData.appKnjig || person.appKnjig || 0],
              ['Doprinosi', userPeriodData.doprinosi || person.doprinosi || 0],
              ['Renta', userPeriodData.renta || person.renta || 0],
              ['Rent Smještaj', userPeriodData.rentSmjestaj || person.rentSmjestaj || 0],
              ['Oprema', userPeriodData.oprema || person.oprema || 0],
              ['Akontacija', userPeriodData.akontacija || person.akontacija || 0],
              ['Gorivo', userPeriodData.gorivo || person.gorivo || 0],
              ['Ostalo', userPeriodData.ostalo || person.ostalo || 0],
              ['Bonus', userPeriodData.bonus || person.bonus || 0],
              ['Tarifa', userPeriodData.tarifa || person.tarifa || 0],
              ['Struja', userPeriodData.struja || person.struja || 0],
              ['Glovo Tax', userPeriodData.glovoTax || person.glovoTax || 0],
              ['Deposit', userPeriodData.depozit || person.depozit || 0],
            ]
              .map(row => `<tr><td>${row[0]}</td><td>${row[1]}</td></tr>`)
              .join('')}
          </table>
        </body>
      </html>
    `;
    printWindow.document.write(documentContent);
    printWindow.document.close(); // Ensure the content is fully loaded before print
    printWindow.focus(); // Required for some browsers
    printWindow.print();
  };
  
  
  

  const handleReportOption = async (format) => {
    console.log(format);
    switch (format) {
      case 'xlsx':
        await downloadExcelReport();
        break;
      case 'pdf':
        downloadPDFReport();
        break;
      case 'print':
        printReport();
        break;
      default:
        break;
    }
  };


  return (
    <>
      <tr>
        <td>{person.name}</td>
        <td>
          {/* Dva odvojena gumba za uređivanje */}
          <button className="edit-button" onClick={openReportModal} title="Report">
          <FaDownload /> Izvještaj
          </button>
          <button className="edit-button" onClick={openPersonModal} title="Uredi osobu">
            <FaEdit /> Osoba
          </button>
          <button className="edit-button" onClick={openFinancialModal} title="Uredi financije">
            <FaEdit /> Finan
          </button>
        </td>
        <td>{person.riderID}</td> {/* Prikazujemo Rider ID */}
        <td>{getFirmName(person.firmId)}</td> {/* Prikazujemo ime firme */}
        <td>{person.nationality === 'Hrvat' ? 'H' : 'S'}</td> {/* Promijenjeni prikaz nacionalnosti */}
        {earnings.map((earning, index) => (
          <td key={index}>{earning.toFixed(2)}</td>
        ))}
        <td>{zaradaTotal.toFixed(2)}</td>
        <td>{fee}%</td>
        <td>{feeAmount}</td> {/* Prikazujemo FEE Iznos */}
        <td>{total1}</td> {/* Prikazujemo Total 1 */}
        <td>{appKnjig.toFixed(2)}</td>
        <td>{doprinosi.toFixed(2)}</td>
        <td>{renta.toFixed(2)}</td>
        <td>{rentSmjestaj.toFixed(2)}</td>
       
          <>
            <td>{tarifa.toFixed(2)}</td>
            <td>{depozit.toFixed(2)}</td>
            <td>{struja.toFixed(2)}</td>
            <td>{glovoTax.toFixed(2)}</td>
          </>
       
        <td>{oprema.toFixed(2)}</td>
        <td>{akontacija.toFixed(2)}</td>
        <td>{gorivo.toFixed(2)}</td>
        <td>{ostalo.toFixed(2)}</td>
        <td>{bonus.toFixed(2)}</td>
        
        <td>{total2}</td> {/* Prikazujemo Total 2 */}
        
      </tr>

      {/* Modal for report options */}
      <ReportModal 
        isOpen={isReportModalOpen} 
        onRequestClose={closeReportModal} 
        onGenerateReport={handleReportOption} 
      />
      
      {/* Modal za uređivanje osobe */}
      <Modal
        isOpen={isPersonModalOpen}
        onRequestClose={closePersonModal}
        className="modal"
        overlayClassName="overlay"
      >
        <h2>Uredi osobu</h2>
        <form className="edit-person-form">
          <div className="form-group">
            <label>Ime:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Nacionalnost:</label>
            <select
              value={nationality}
              onChange={(e) => setNationality(e.target.value)}
            >
              <option value="Hrvat">Hrvat</option>
              <option value="Stranac">Stranac</option>
            </select>
          </div>
          <div className="form-group">
            <label>Rider ID:</label> {/* Dodajemo Rider ID */}
            <input
              type="text"
              value={riderID}
              onChange={(e) => setRiderID(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Firma:</label> {/* Dodajemo polje za odabir firme */}
            <select
              value={firmId}
              onChange={(e) => setFirmId(e.target.value)}
              required
            >
              <option value="default">Default</option>
              {firms.filter((firm) => firm.id !== 'default').map((firm) => (
                <option key={firm.id} value={firm.id}>
                  {firm.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Tekući račun:</label>
            <input
              type="text"
              value={tekuciRacun}
              onChange={(e) => setTekuciRacun(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Zaštićeni račun:</label>
            <input
              type="text"
              value={zasticeniRacun}
              onChange={(e) => setZasticeniRacun(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>App Knjig. (default):</label>
            <input
              type="number"
              value={appKnjigDefault}
              onChange={(e) => setAppKnjigDefault(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div className="form-group">
            <label>Doprinosi (default):</label>
            <input
              type="number"
              value={doprinosiDefault}
              onChange={(e) => setDoprinosiDefault(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div className="form-group">
            <label>Fee % (default):</label>
            <input
              type="number"
              value={feeDefault}
              onChange={(e) => setFeeDefault(parseFloat(e.target.value) || 0)}
              min="0"
              max="100"
            />
          </div>
          <div className="form-group">
            <label>Renta (default):</label>
            <input
              type="number"
              value={rentaDefault}
              onChange={(e) => setRentaDefault(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div className="form-group">
            <label>Rent smještaj (default):</label>
            <input
              type="number"
              value={rentSmjestajDefault}
              onChange={(e) => setRentSmjestajDefault(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div className="form-group">
            <label>Oprema (default):</label>
            <input
              type="number"
              value={opremaDefault}
              onChange={(e) => setOpremaDefault(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div className="form-group">
            <label>Akontacija (default):</label>
            <input
              type="number"
              value={akontacijaDefault}
              onChange={(e) => setAkontacijaDefault(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div className="form-group">
            <label>Gorivo (default):</label>
            <input
              type="number"
              value={gorivoDefault}
              onChange={(e) => setGorivoDefault(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div className="form-group">
            <label>Ostalo (default):</label>
            <input
              type="number"
              value={ostaloDefault}
              onChange={(e) => setOstaloDefault(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div className="form-group">
            <label>Bonus (default):</label>
            <input
              type="number"
              value={bonusDefault}
              onChange={(e) => setBonusDefault(parseFloat(e.target.value) || 0)}
            />
          </div>
            {/* Additional fields for 'Stranac' */}
            {nationality === 'Stranac' && (
              <>
                <div className="form-group">
                  <label>Tarifa (default):</label>
                  <input
                    type="number"
                    value={tarifaDefault}
                    onChange={(e) => setTarifaDefault(parseFloat(e.target.value) || 0)}
                  />
                </div>
                <div className="form-group">
                  <label>Depozit (default):</label>
                  <input
                    type="number"
                    value={depozitDefault}
                    onChange={(e) => setDepozitDefault(parseFloat(e.target.value) || 0)}
                  />
                </div>
                <div className="form-group">
                  <label>Struja (default):</label>
                  <input
                    type="number"
                    value={strujaDefault}
                    onChange={(e) => setStrujaDefault(parseFloat(e.target.value) || 0)}
                  />
                </div>
                <div className="form-group">
                  <label>Glovo Tax (default):</label>
                  <input
                    type="number"
                    value={glovoTaxDefault}
                    onChange={(e) => setGlovoTaxDefault(parseFloat(e.target.value) || 0)}
                  />
                </div>
              </>
            )}

          <div className="button-group">
            <button type="button" className="save-button" onClick={handleSavePerson}>
              Spremi
            </button>
            <button type="button" className="cancel-button" onClick={closePersonModal}>
              Odustani
            </button>
            <button type="button" className="delete-button" onClick={handleDelete}>
              <FaTrash /> Izbriši
            </button>
          </div>
        </form>
      </Modal>

      {/* Modal za uređivanje financijskih podataka */}
      <Modal
        isOpen={isFinancialModalOpen}
        onRequestClose={closeFinancialModal}
        className="modal"
        overlayClassName="overlay"
      >
        <h2>Uredi financijske podatke</h2>
        <form className="edit-person-form">
          <div className="form-group">
            <label>Fee %:</label>
            <input
              type="number"
              value={fee}
              onChange={(e) => setFee(parseFloat(e.target.value) || 0)}
              min="0"
              max="100"
            />
          </div>
          <div className="form-group">
            <label>App Knjig.:</label>
            <input
              type="number"
              value={appKnjig}
              onChange={(e) => setAppKnjig(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div className="form-group">
            <label>Doprinosi:</label>
            <input
              type="number"
              value={doprinosi}
              onChange={(e) => setDoprinosi(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div className="form-group">
            <label>Renta:</label>
            <input
              type="number"
              value={renta}
              onChange={(e) => setRenta(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div className="form-group">
            <label>Rent smještaj:</label>
            <input
              type="number"
              value={rentSmjestaj}
              onChange={(e) => setRentSmjestaj(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div className="form-group">
            <label>Oprema:</label>
            <input
              type="number"
              value={oprema}
              onChange={(e) => setOprema(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div className="form-group">
            <label>Akontacija:</label>
            <input
              type="number"
              value={akontacija}
              onChange={(e) => setAkontacija(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div className="form-group">
            <label>Gorivo:</label>
            <input
              type="number"
              value={gorivo}
              onChange={(e) => setGorivo(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div className="form-group">
            <label>Ostalo:</label>
            <input
              type="number"
              value={ostalo}
              onChange={(e) => setOstalo(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div className="form-group">
            <label>Bonus:</label>
            <input
              type="number"
              value={bonus}
              onChange={(e) => setBonus(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div className="form-group">
            <label>Napomena:</label>
            <input
              type="text"
              value={napomena}
              onChange={(e) => setNapomena(e.target.value)}
            />
          </div>

              {/* Additional fields for 'Stranac' */}
          {person.nationality === 'Stranac' && (
            <>
              <div className="form-group">
                <label>Tarifa:</label>
                <input
                  type="number"
                  value={tarifa}
                  onChange={(e) => setTarifa(parseFloat(e.target.value) || 0)}
                />
              </div>
              <div className="form-group">
                <label>Depozit:</label>
                <input
                  type="number"
                  value={depozit}
                  onChange={(e) => setDepozit(parseFloat(e.target.value) || 0)}
                />
              </div>
              <div className="form-group">
                <label>Struja:</label>
                <input
                  type="number"
                  value={struja}
                  onChange={(e) => setStruja(parseFloat(e.target.value) || 0)}
                />
              </div>
              <div className="form-group">
                <label>Glovo Tax:</label>
                <input
                  type="number"
                  value={glovoTax}
                  onChange={(e) => setGlovoTax(parseFloat(e.target.value) || 0)}
                />
              </div>
            </>
          )}

          <h3>Zarade po Tjednima</h3>
          {[...Array(numOfWeeks)].map((_, i) => {
            const weekNumber = startWeek + i;
            return (
              <div className="form-group" key={i}>
                <label>Tjedan {weekNumber}:</label>
                <input
                  type="number"
                  value={earnings[i]}
                  onChange={(e) => handleEarningChange(e, i)}
                />
              </div>
            );
          })}
          <div className="button-group">
            <button type="button" className="save-button" onClick={handleSaveFinancial}>
              Spremi
            </button>
            <button type="button" className="cancel-button" onClick={closeFinancialModal}>
              Odustani
            </button>
            <button type="button" className="delete-button" onClick={handleDeleteFinancialData}>
              Resetiraj na zadane vrijednosti
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default PersonRow;
