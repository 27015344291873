// PersonForm.jsx

import React, { useState } from 'react';
import Modal from 'react-modal';
import '../css/PersonForm.css';

function PersonForm({ onAddPerson, firms }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [name, setName] = useState('');
  const [nationality, setNationality] = useState('Hrvat');
  const [fee, setFee] = useState(0);
  const [appKnjig, setAppKnjig] = useState(0);
  const [doprinosi, setDoprinosi] = useState(0);
  const [renta, setRenta] = useState(0);
  const [rentSmjestaj, setRentSmjestaj] = useState(0);
  const [oprema, setOprema] = useState(0);
  const [akontacija, setAkontacija] = useState(0);
  const [gorivo, setGorivo] = useState(0);
  const [ostalo, setOstalo] = useState(0);
  const [bonus, setBonus] = useState(0);
  const [tekuciRacun, setTekuciRacun] = useState('');
  const [zasticeniRacun, setZasticeniRacun] = useState('');
  const [riderID, setRiderID] = useState('');
  const [firmId, setFirmId] = useState('default');

  // New state variables for foreigners
  const [tarifa, setTarifa] = useState(0);
  const [depozit, setDepozit] = useState(0);
  const [struja, setStruja] = useState(0);
  const [glovoTax, setGlovoTax] = useState(0);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    // Resetting form state when closing
    setName('');
    setNationality('Hrvat');
    setFee(0);
    setAppKnjig(0);
    setDoprinosi(0);
    setRenta(0);
    setRentSmjestaj(0);
    setOprema(0);
    setAkontacija(0);
    setGorivo(0);
    setOstalo(0);
    setBonus(0);
    setTekuciRacun('');
    setZasticeniRacun('');
    setRiderID('');
    setFirmId('default');
    setTarifa(0);
    setDepozit(0);
    setStruja(0);
    setGlovoTax(0);
    setModalIsOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare person object
    const person = {
      name,
      nationality,
      fee,
      appKnjig,
      doprinosi,
      renta,
      rentSmjestaj,
      oprema,
      akontacija,
      gorivo,
      ostalo,
      bonus,
      tekuciRacun,
      zasticeniRacun,
      riderID,
      firmId,
    };

    // If nationality is 'Stranac', include additional fields
    if (nationality === 'Stranac') {
      person.tarifa = tarifa;
      person.depozit = depozit;
      person.struja = struja;
      person.glovoTax = glovoTax;
    }

    // Pass data to parent component
    onAddPerson(person);
    closeModal();
  };

  return (
    <div>
      <button className="add-person-button" onClick={openModal}>
        Dodaj osobu
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal"
        overlayClassName="overlay"
      >
        <h2>Dodaj osobu</h2>
        <form onSubmit={handleSubmit} className="person-form">
          <div className="form-group">
            <label>Ime:</label>
            <input
              type="text"
              placeholder="Ime"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Nacionalnost:</label>
            <select
              value={nationality}
              onChange={(e) => setNationality(e.target.value)}
            >
              <option value="Hrvat">Hrvat</option>
              <option value="Stranac">Stranac</option>
            </select>
          </div>
          <div className="form-group">
            <label>Rider ID:</label>
            <input
              type="text"
              placeholder="Rider ID"
              value={riderID}
              onChange={(e) => setRiderID(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Firma:</label>
            <select
              value={firmId}
              onChange={(e) => setFirmId(e.target.value)}
              required
            >
              {firms.map((firm) => (
                <option key={firm.id} value={firm.id}>
                  {firm.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Fee %:</label>
            <input
              type="number"
              placeholder="Fee %"
              value={fee}
              onChange={(e) => setFee(parseFloat(e.target.value))}
              required
            />
          </div>
          <div className="form-group">
            <label>App Knjig.:</label>
            <input
              type="number"
              placeholder="App Knjig."
              value={appKnjig}
              onChange={(e) => setAppKnjig(parseFloat(e.target.value))}
              required
            />
          </div>
          <div className="form-group">
            <label>Doprinosi:</label>
            <input
              type="number"
              placeholder="Doprinosi"
              value={doprinosi}
              onChange={(e) => setDoprinosi(parseFloat(e.target.value))}
              required
            />
          </div>
          {/* New default fields */}
          <div className="form-group">
            <label>Renta (default):</label>
            <input
              type="number"
              value={renta}
              onChange={(e) => setRenta(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div className="form-group">
            <label>Rent smještaj (default):</label>
            <input
              type="number"
              value={rentSmjestaj}
              onChange={(e) => setRentSmjestaj(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div className="form-group">
            <label>Oprema (default):</label>
            <input
              type="number"
              value={oprema}
              onChange={(e) => setOprema(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div className="form-group">
            <label>Akontacija (default):</label>
            <input
              type="number"
              value={akontacija}
              onChange={(e) => setAkontacija(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div className="form-group">
            <label>Gorivo (default):</label>
            <input
              type="number"
              value={gorivo}
              onChange={(e) => setGorivo(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div className="form-group">
            <label>Ostalo (default):</label>
            <input
              type="number"
              value={ostalo}
              onChange={(e) => setOstalo(parseFloat(e.target.value) || 0)}
            />
          </div>
          <div className="form-group">
            <label>Bonus (default):</label>
            <input
              type="number"
              value={bonus}
              onChange={(e) => setBonus(parseFloat(e.target.value) || 0)}
            />
          </div>

          {/* Additional fields for foreigners */}
          {nationality === 'Stranac' && (
            <>
              <div className="form-group">
                <label>Tarifa (default):</label>
                <input
                  type="number"
                  value={tarifa}
                  onChange={(e) => setTarifa(parseFloat(e.target.value) || 0)}
                />
              </div>
              <div className="form-group">
                <label>Depozit (default):</label>
                <input
                  type="number"
                  value={depozit}
                  onChange={(e) => setDepozit(parseFloat(e.target.value) || 0)}
                />
              </div>
              <div className="form-group">
                <label>Struja (default):</label>
                <input
                  type="number"
                  value={struja}
                  onChange={(e) => setStruja(parseFloat(e.target.value) || 0)}
                />
              </div>
              <div className="form-group">
                <label>Glovo Tax (default):</label>
                <input
                  type="number"
                  value={glovoTax}
                  onChange={(e) => setGlovoTax(parseFloat(e.target.value) || 0)}
                />
              </div>
            </>
          )}

          <div className="form-group">
            <label>Tekući račun:</label>
            <input
              type="text"
              placeholder="Tekući račun"
              value={tekuciRacun}
              onChange={(e) => setTekuciRacun(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Zaštićeni račun:</label>
            <input
              type="text"
              placeholder="Zaštićeni račun"
              value={zasticeniRacun}
              onChange={(e) => setZasticeniRacun(e.target.value)}
            />
          </div>
          <div className="button-group">
            <button type="submit" className="save-button">
              Spremi
            </button>
            <button type="button" onClick={closeModal} className="cancel-button">
              Odustani
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default PersonForm;
