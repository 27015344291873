// ExcelUploader.jsx

import React, { useRef } from 'react';
import * as XLSX from 'xlsx';

function ExcelUploader({ onDataParsed, children, customClass }) {
  const fileInputRef = useRef();

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;
  
    const reader = new FileReader();
    reader.onload = (evt) => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
  
      if (fileExtension === 'csv') {
        // Handling CSV
        const csvData = evt.target.result;
        // Parse CSV data using XLSX.read with type 'binary'
        const workbook = XLSX.read(csvData, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        let json = XLSX.utils.sheet_to_json(worksheet);
  
        console.log('Raw CSV Data:', json);
        onDataParsed(json, file.name);
      } else {
        // Handling Excel (.xlsx, .xls)
        const data = new Uint8Array(evt.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        let json = XLSX.utils.sheet_to_json(worksheet);
  
        console.log('Raw Excel Data:', json);
        json = normalizeKeys(json);
        onDataParsed(json, file.name);
      }
    };
  
    if (file.name.endsWith('.csv')) {
      reader.readAsBinaryString(file);  // CSV files should be read as a binary string for XLSX.read
    } else {
      reader.readAsArrayBuffer(file);  // Excel files should be read as ArrayBuffer
    }
  };
  

  // Funkcija za normalizaciju ključeva i vrijednosti: mala slova bez praznina
  const normalizeKeys = (data) => {
    return data.map((item) => {
      const normalizedItem = {};
      Object.keys(item).forEach((key) => {
        const normalizedKey = key.toLowerCase().replace(/\s+/g, ''); // Uklanjamo sve praznine
        let value = item[key];

        // Ako je vrijednost string, trimamo i pretvaramo u mala slova
        if (typeof value === 'string') {
          value = value.trim().toLowerCase();
        }

        normalizedItem[normalizedKey] = value;
      });
      return normalizedItem;
    });
  };

  return (
    <span
      className={customClass}
      onClick={handleClick}
      style={{ cursor: 'pointer', textDecoration: 'underline' }}
    >
      {children}
      <input
        type="file"
        accept=".xlsx, .xls, .csv"
        onChange={handleFileUpload}
        style={{ display: 'none' }}
        ref={fileInputRef}
      />
    </span>
  );
}

export default ExcelUploader;