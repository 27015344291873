// src/components/FirmManager.jsx

import React, { useState, useEffect } from 'react';
import { db } from '../config/firebase';
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  updateDoc,
  deleteDoc,
  doc,
  setDoc,
  onSnapshot
} from 'firebase/firestore';
import Modal from 'react-modal';
import { FaEdit, FaTrash } from 'react-icons/fa';
import '../css/FirmManager.css'; // Kreirajte ovaj CSS fajl za stilove

Modal.setAppElement('#root'); // Potrebno za pristupačnost modala

function FirmManager() {
    const [firms, setFirms] = useState([]);
    const [newFirmName, setNewFirmName] = useState('');
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentFirm, setCurrentFirm] = useState(null);
    const [editedFirmName, setEditedFirmName] = useState('');
  
    const firmsCollection = collection(db, 'firms');
  
    // Funkcija za dohvaćanje svih firmi
    const fetchFirms = async () => {
      try {
        const q = query(firmsCollection);
        const querySnapshot = await getDocs(q);
        const firmsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setFirms(firmsData);
      } catch (error) {
        console.error('Greška pri dohvaćanju firmi:', error);
      }
    };
  
    // Provjera i kreiranje zadane firme ako ne postoji
    const ensureDefaultFirm = async () => {
      try {
        const q = query(firmsCollection, where('__name__', '==', 'default'));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          await addDoc(firmsCollection, {
            name: 'Default',
            createdAt: new Date()
          });
          console.log('Zadana firma kreirana.');
        } else {
          console.log('Zadana firma već postoji.');
        }
      } catch (error) {
        console.error('Greška pri provjeri/zadavanju firme:', error);
      }
    };
  
    useEffect(() => {
      ensureDefaultFirm();
      fetchFirms();
  
      // Real-time ažuriranje firmi
      const unsubscribe = onSnapshot(firmsCollection, (snapshot) => {
        const firmsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setFirms(firmsData);
      });
  
      return () => unsubscribe();
    }, []);
  
    // Funkcija za dodavanje nove firme
    const handleAddFirm = async (e) => {
      e.preventDefault();
      if (newFirmName.trim() === '') {
        alert('Ime firme ne može biti prazno.');
        return;
      }
  
      // Provjera postoji li već firma s istim imenom
      const q = query(firmsCollection, where('name', '==', newFirmName.trim()));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        alert('Firma s tim imenom već postoji.');
        return;
      }
  
      try {
        await addDoc(firmsCollection, {
          name: newFirmName.trim(),
          createdAt: new Date()
        });
        setNewFirmName('');
        alert('Firma uspješno dodana.');
      } catch (error) {
        console.error('Greška pri dodavanju firme:', error);
        alert('Došlo je do greške prilikom dodavanja firme.');
      }
    };
  
    // Funkcija za otvaranje edit modala
    const openEditModal = (firm) => {
      setCurrentFirm(firm);
      setEditedFirmName(firm.name);
      setIsEditModalOpen(true);
    };
  
    // Funkcija za zatvaranje edit modala
    const closeEditModal = () => {
      setIsEditModalOpen(false);
      setCurrentFirm(null);
      setEditedFirmName('');
    };
  
    // Funkcija za spremanje izmjena firme
    const handleSaveEdit = async () => {
      if (editedFirmName.trim() === '') {
        alert('Ime firme ne može biti prazno.');
        return;
      }
  
      // Provjera postoji li već firma s istim imenom
      const q = query(firmsCollection, where('name', '==', editedFirmName.trim()));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty && querySnapshot.docs[0].id !== currentFirm.id) {
        alert('Firma s tim imenom već postoji.');
        return;
      }
  
      try {
        const firmDoc = doc(db, 'firms', currentFirm.id);
        await updateDoc(firmDoc, {
          name: editedFirmName.trim()
        });
        closeEditModal();
        alert('Firma uspješno ažurirana.');
      } catch (error) {
        console.error('Greška pri ažuriranju firme:', error);
        alert('Došlo je do greške prilikom ažuriranja firme.');
      }
    };
  
    // Funkcija za brisanje firme
    const handleDeleteFirm = async (firm) => {
      if (firm.id === 'default') {
        alert('Zadana firma ne može biti izbrisana.');
        return;
      }
  
      if (!window.confirm(`Jeste li sigurni da želite izbrisati firmu "${firm.name}"?`)) {
        return;
      }
  
      try {
        await deleteDoc(doc(db, 'firms', firm.id));
        alert('Firma uspješno izbrisana.');
        // Kasnije ćemo prebaciti osobe u default firmu
      } catch (error) {
        console.error('Greška pri brisanju firme:', error);
        alert('Došlo je do greške prilikom brisanja firme.');
      }
    };
  
    return (
      <div className="firm-manager-container">
        <h2>Upravljanje Firmama</h2>
        
        {/* Forma za dodavanje nove firme */}
        <form onSubmit={handleAddFirm} className="add-firm-form">
          <input
            type="text"
            value={newFirmName}
            onChange={(e) => setNewFirmName(e.target.value)}
            placeholder="Naziv nove firme"
          />
          <button type="submit">Dodaj Firmu</button>
        </form>
  
        {/* Lista firmi */}
        <table className="firms-table">
          <thead>
            <tr>
              <th>Naziv Firme</th>
              <th>Akcije</th>
            </tr>
          </thead>
          <tbody>
            {firms.map((firm) => (
              <tr key={firm.id}>
                <td>{firm.name}</td>
                <td>
                  {firm.id !== 'default' && (
                    <>
                      <button onClick={() => openEditModal(firm)} className="edit-button" title="Uredi firmu">
                        <FaEdit />
                      </button>
                      <button onClick={() => handleDeleteFirm(firm)} className="delete-button" title="Izbriši firmu">
                        <FaTrash />
                      </button>
                    </>
                  )}
                  {firm.id === 'default' && <span style={{ color: '#888' }}>N/A</span>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
  
        {/* Modal za uređivanje firme */}
        <Modal
          isOpen={isEditModalOpen}
          onRequestClose={closeEditModal}
          contentLabel="Uredi firmu"
          className="modal"
          overlayClassName="overlay"
        >
          <h2>Uredi Firmu</h2>
          <form className="edit-firm-form">
            <div className="form-group">
              <label>Naziv Firme:</label>
              <input
                type="text"
                value={editedFirmName}
                onChange={(e) => setEditedFirmName(e.target.value)}
              />
            </div>
            <div className="button-group">
              <button type="button" onClick={handleSaveEdit} className="save-button">
                Spremi
              </button>
              <button type="button" onClick={closeEditModal} className="cancel-button">
                Odustani
              </button>
            </div>
          </form>
        </Modal>
      </div>
    );
  }
  
export default FirmManager;