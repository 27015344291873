import React from 'react';
import Modal from 'react-modal';
import '../css/PersonRow.css'; // Stilovi za komponentu gumbe
// Ensure to set the app element for accessibility
Modal.setAppElement('#root'); // Change '#root' to your main app element ID

const ReportModal = ({ isOpen, onRequestClose, onGenerateReport }) => {
  const handleDownload = (format) => {
    onGenerateReport(format);
    onRequestClose(); // Close the modal after selection
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="modal" overlayClassName="overlay">
      <h2>Izaberi format izvještaja</h2>
      <div>
        <button className="edit-button" onClick={() => handleDownload('xlsx')}>Download XLSX</button>
        <button className="edit-button" onClick={() => handleDownload('pdf')}>Download PDF</button>
        <button className="edit-button" onClick={() => handleDownload('print')}>Print</button>
      </div>
      <button onClick={onRequestClose}>Close</button>
    </Modal>
  );
};

export default ReportModal;
