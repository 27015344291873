// src/pages/Metrike.jsx

import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../config/AuthProvider';
import Login from '../authPage/Login';
import MetricsTable from '../components/MetricsTable';
import { db } from '../config/firebase';
import { collection, getDocs, query, where, addDoc, deleteDoc, doc, setDoc, updateDoc } from 'firebase/firestore';
import Modal from 'react-modal';
import FirmManager from '../components/FirmManager';
import '../css/Metrike.css'; // Importamo Metrike.css

function Metrike() {
  const [people, setPeople] = useState([]);
  const [dailyMetricsData, setDailyMetricsData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedNationality, setSelectedNationality] = useState('Sve');
  const [firms, setFirms] = useState([]);
  const [selectedFirm, setSelectedFirm] = useState('');
  const { currentUser } = useContext(AuthContext);
  const [isFirmManagerOpen, setIsFirmManagerOpen] = useState(false);
  
  // Novo stanje za uploadane dane
  const [uploadedDays, setUploadedDays] = useState([]);
  const [isUploadDaysModalOpen, setIsUploadDaysModalOpen] = useState(false);
  const [filterFirm, setFilterFirm] = useState(''); // Za filtriranje unutar modala

  // Novo stanje za metricke postavke
  const [isMetrickePostavkeModalOpen, setIsMetrickePostavkeModalOpen] = useState(false);
  const [minDailyStranci, setMinDailyStranci] = useState('');
  const [minDailyHrvati, setMinDailyHrvati] = useState('');
  const [minMonthlyStranci, setMinMonthlyStranci] = useState('');
  const [minMonthlyHrvati, setMinMonthlyHrvati] = useState('');

  // Funkcija za dohvaćanje korisnika iz Firestorea
  const fetchUsers = async () => {
    try {
      const qUsers = query(collection(db, 'users'));
      const querySnapshot = await getDocs(qUsers);
      const users = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPeople(users);
      console.log('Dohvaćeni korisnici:', users);
    } catch (e) {
      console.error('Greška pri dohvaćanju korisnika:', e);
    }
  };

  // Funkcija za dohvaćanje metrika iz Firestorea
  const fetchDailyMetrics = async () => {
    try {
      const qMetrics = query(
        collection(db, 'dailyMetrics'),
        where('year', '==', selectedYear),
        where('month', '==', selectedMonth)
      );
      const querySnapshot = await getDocs(qMetrics);
      const metrics = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setDailyMetricsData(metrics);
      console.log('Dohvaćene dnevne metrike:', metrics);
    } catch (e) {
      console.error('Greška pri dohvaćanju dnevnih metrika:', e);
    }
  };

  // Funkcija za dohvaćanje firmi
  const fetchFirms = async () => {
    try {
      const firmsCollection = collection(db, 'firms');
      const q = query(firmsCollection);
      const querySnapshot = await getDocs(q);
      const firmsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setFirms(firmsData);
    } catch (error) {
      console.error('Greška pri dohvaćanju firmi:', error);
    }
  };

  // Funkcija za dohvaćanje metrickih postavki
  const fetchMetrickePostavke = async () => {
    try {
      const docRef = doc(db, 'metrickePostavke', 'default');
      const docSnap = await getDocs(query(collection(db, 'metrickePostavke'), where('__name__', '==', 'default')));
      if (!docSnap.empty) {
        const data = docSnap.docs[0].data();
        setMinDailyStranci(data.minDailyStranci);
        setMinDailyHrvati(data.minDailyHrvati);
        setMinMonthlyStranci(data.minMonthlyStranci);
        setMinMonthlyHrvati(data.minMonthlyHrvati);
      }
    } catch (e) {
      console.error('Greška pri dohvaćanju metrickih postavki:', e);
    }
  };

  // Funkcija za dohvaćanje uploadanih dana iz "uploadedDays" kolekcije
  const fetchUploadedDays = async () => {
    try {
      const qUploadedDays = query(
        collection(db, 'uploadedDays'),
        where('year', '==', selectedYear),
        where('month', '==', selectedMonth)
        // Uklonjen filter za firmId kako bi dohvatili sve dane
      );

      const querySnapshot = await getDocs(qUploadedDays);
      const days = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        day: doc.data().day,
        filename: doc.data().filename,
        year: doc.data().year,
        month: doc.data().month,
        firmId: doc.data().firmId
      }));
      setUploadedDays(days);
    } catch (e) {
      console.error('Greška pri dohvaćanju uploadedDays:', e);
    }
  };

  // Dohvaćanje podataka kad se promijene odabrani mjesec ili godina
  useEffect(() => {
    if (currentUser) {
      fetchUsers();
      fetchFirms();
      fetchDailyMetrics();
      fetchUploadedDays(); // Dohvaćanje uploadanih dana
      fetchMetrickePostavke(); // Dohvaćanje metrickih postavki
    }
  }, [currentUser, selectedYear, selectedMonth]);

  // Funkcija za osvježavanje podataka
  const refreshData = () => {
    fetchUsers();
    fetchFirms();
    fetchDailyMetrics();
    fetchUploadedDays(); // Osvježavanje uploadanih dana
    fetchMetrickePostavke(); // Osvježavanje metrickih postavki
  };

  // Funkcija za ažuriranje napomene (ako je potrebno, može ostati ista)
  const updateNapomena = async (userId, year, month, day, newNapomena) => {
    try {
      const napomenaQuery = query(
        collection(db, 'dailyMetrics'),
        where('userId', '==', userId),
        where('year', '==', year),
        where('month', '==', month),
        where('day', '==', day)
      );

      const napomenaSnapshot = await getDocs(napomenaQuery);

      if (!napomenaSnapshot.empty) {
        const docRef = napomenaSnapshot.docs[0].ref;
        await updateDoc(docRef, {
          napomena: newNapomena,
        });
      } else {
        // Ako dokument ne postoji, kreiraj novi
        await addDoc(collection(db, 'dailyMetrics'), {
          userId,
          year,
          month,
          day,
          napomena: newNapomena,
          amount: 0, // Inicijalna vrijednost zarade
        });
      }

      // Osvježavanje podataka nakon ažuriranja
      refreshData();
    } catch (e) {
      console.error('Greška pri ažuriranju napomene:', e);
      alert('Došlo je do greške prilikom ažuriranja napomene. Pokušajte ponovno.');
    }
  };

  // Funkcija za prebacivanje korisnika u Default firmu prilikom brisanja firme
  const moveUsersToDefaultFirm = async (firmIdToDelete) => {
    try {
      // Dohvaćanje svih korisnika koji pripadaju obrisanoj firmi
      const qUsers = query(
        collection(db, 'users'),
        where('firmId', '==', firmIdToDelete)
      );
      const usersSnapshot = await getDocs(qUsers);

      const updatePromises = usersSnapshot.docs.map((docSnap) =>
        updateDoc(doc(db, 'users', docSnap.id), { firmId: 'default' })
      );

      await Promise.all(updatePromises);
      console.log(`Sve osobe iz firme ${firmIdToDelete} prebačene u Default firmu.`);
      // Osvježavanje podataka nakon prebacivanja
      refreshData();
    } catch (error) {
      console.error('Greška pri prebacivanju korisnika u Default firmu:', error);
      alert('Došlo je do greške prilikom prebacivanja korisnika u Default firmu.');
    }
  };

  // Funkcija za ažuriranje dnevnih metrika
  // Function to update daily metrics
  const updateDailyMetrics = async (day, data, filename) => {
    console.log('Excel Data:', data);
    console.log('Current users:', people);
    console.log('Day:', day);
  
    const year = selectedYear;
    const month = selectedMonth;
    const firmId = selectedFirm; // We use the selected firm
    const uniqueDayId = `${year}-${month}-${day}-${firmId}`; // We include firmId in the ID
  
    // Validation: Check if a firm is selected
    if (firmId === '') {
      alert('Molimo odaberite firmu prije upload-a.');
      return;
    }
  
    // Check if the day has already been imported for that firm
    const existingDay = uploadedDays.find(
      (d) => d.day === day && d.year === year && d.month === month && d.firmId === firmId
    );
  
    if (existingDay) {
      alert(`Dan ${day} za ${month}/${year} i firmu ${getFirmName(firmId)} je već uvezen.`);
      return; // Stop the process if the day has already been imported for that firm
    }
  
    console.log(data);
    for (const item of data) {
      
      // Check and normalize Rider ID
      const riderIDRaw = item['rider_id']; // Updated column name
      console.log('Original Rider ID from Excel:', riderIDRaw);
  
      let riderID = null;
      if (typeof riderIDRaw === 'string') {
        riderID = riderIDRaw.trim().toLowerCase();
      } else if (riderIDRaw != null) {
        // If it's not a string, try to convert it to a string
        riderID = String(riderIDRaw).trim().toLowerCase();
      }
      console.log("RIDER ID parsiran", riderID)
  
      if (!riderID) {
        console.log('Rider ID nije prisutan ili je neispravan u Excel podacima za unos.');
        continue;
      }
  
      // Find the person with the corresponding Rider ID
      const person = people.find(
        (p) => p.riderID && p.riderID.trim().toLowerCase() === riderID
      );
  
      if (person) {
        // Define possible keys for earnings
        const metricsKeys = ["Earnings (local currency)", "earnings(localcurrency)"];
        let metricsValue;
  
        for (const metricsKey of metricsKeys) {
          const metricsValueRaw = item[metricsKey];
  
          if (metricsValueRaw !== undefined) {
            console.log(`Raw earnings for ${person.name} on day ${day}:`, metricsValueRaw);
  
            // Parse the earnings value to a number
            metricsValue = parseFloat(String(metricsValueRaw).replace(/[^0-9.-]+/g, ""));
  
            // If metricsValue is successfully parsed, break out of the loop
            if (!isNaN(metricsValue)) {
              break; // Exit the loop once a valid value is found
            } else {
              console.log(`Earnings value "${metricsValueRaw}" for column "${metricsKey}" could not be parsed for rider ID ${riderID}`);
            }
          }
        }
  
        if (metricsValue !== undefined) {
          // Save the metric to Firestore
          try {
            await addDoc(collection(db, 'dailyMetrics'), {
              userId: person.id,
              year,
              month,
              day,
              amount: metricsValue,
              firmId, // Add firmId to metrics
              filename, // We can store the filename if needed
            });
            console.log(`Earnings saved for ${person.name} on day ${day}`);
          } catch (e) {
            console.error('Error saving metric:', e);
          }
        } else {
          console.log(`No valid earnings found for Rider ID: ${riderID}`);
        }
      } else {
        console.log(`No user found for Rider ID: ${riderID}`);
      }
    }
  
    // After all data has been processed, we add the day to 'uploadedDays'
    try {
      await setDoc(doc(db, 'uploadedDays', uniqueDayId), {
        day,
        year,
        month,
        filename,
        firmId, // Add firmId
      });
      setUploadedDays([...uploadedDays, { id: uniqueDayId, day, year, month, filename, firmId }]);
      alert(`Dan ${day} za ${month}/${year} i firmu ${getFirmName(firmId)} je uspješno uvezen.`);
    } catch (e) {
      console.error('Error adding to uploadedDays:', e);
      alert('Došlo je do greške prilikom označavanja dana kao učitanog.');
    }
  
    // After saving, refresh the data
    refreshData();
  };
  


  // Funkcija za dohvaćanje imena firme na temelju firmId
  const getFirmName = (firmId) => {
    const firm = firms.find(firm => firm.id === firmId);
    return firm ? firm.name : 'Default';
  };

  // Funkcija za brisanje dana iz uploadedDays
  const deleteUploadedDay = async (day, firmId) => {
    if (!window.confirm(`Jeste li sigurni da želite obrisati dan ${day} za firmu ${getFirmName(firmId)}?`)) {
      return;
    }

    try {
      // Dohvaćanje uploadanih dana koji odgovaraju odabranom danu, godini, mjesecu i firmi
      const qDays = query(
        collection(db, 'uploadedDays'),
        where('day', '==', day),
        where('year', '==', selectedYear),
        where('month', '==', selectedMonth),
        where('firmId', '==', firmId)
      );

      const daysSnapshot = await getDocs(qDays);

      if (daysSnapshot.empty) {
        alert('Nema uploadanih dana za ovaj kriterij.');
        return;
      }

      // Brisanje metrike iz "dailyMetrics" kolekcije za taj dan i firmu
      for (const dayDoc of daysSnapshot.docs) {
        const dayData = dayDoc.data();
        const firmId = dayData.firmId;

        // Brisanje metrike iz "dailyMetrics" kolekcije
        const qMetrics = query(
          collection(db, 'dailyMetrics'),
          where('year', '==', selectedYear),
          where('month', '==', selectedMonth),
          where('day', '==', day),
          where('firmId', '==', firmId)
        );
        const metricsSnapshot = await getDocs(qMetrics);

        const deletePromises = metricsSnapshot.docs.map((docSnap) =>
          deleteDoc(doc(db, 'dailyMetrics', docSnap.id))
        );

        await Promise.all(deletePromises);
        console.log(`Sve metrike za dan ${day} i firmu ${getFirmName(firmId)} su izbrisane.`);

        // Brisanje iz "uploadedDays" kolekcije
        await deleteDoc(doc(db, 'uploadedDays', dayDoc.id));
        console.log(`Dan ${day} za firmu ${getFirmName(firmId)} je izbrisan iz uploadedDays.`);

        // Ažuriranje stanja uploadedDays
        setUploadedDays(uploadedDays.filter((d) => d.id !== dayDoc.id));
      }

      // Ažuriramo metrike nakon brisanja
      refreshData();
    } catch (e) {
      console.error(`Greška pri brisanju dana ${day} i firme ${firmId}:`, e);
    }
  };

  // Funkcija za spremanje metrickih postavki
  const handleSaveMetrickePostavke = async (e) => {
    e.preventDefault();
    try {
      await setDoc(doc(db, 'metrickePostavke', 'default'), {
        minDailyStranci: Number(minDailyStranci),
        minDailyHrvati: Number(minDailyHrvati),
        minMonthlyStranci: Number(minMonthlyStranci),
        minMonthlyHrvati: Number(minMonthlyHrvati),
      });
      alert('Metricke postavke uspješno spremljene.');
      setIsMetrickePostavkeModalOpen(false);
    } catch (error) {
      console.error('Greška pri spremanju metrickih postavki:', error);
      alert('Došlo je do greške prilikom spremanja postavki.');
    }
  };

  return (
    <div className="metrike-container">
      <h1>Metričke Evidencije</h1>

      {currentUser ? (
        <div>
          {/* Filtri za nacionalnost, godinu, mjesec i firmu */}
          <div className="filter-form">
            <label>Nacionalnost:</label>
            <select
              value={selectedNationality}
              onChange={(e) => setSelectedNationality(e.target.value)}
            >
              <option value="Sve">Sve</option>
              <option value="Hrvat">Hrvat</option>
              <option value="Stranac">Stranac</option>
            </select>

            <label>Firma:</label>
            <select
              value={selectedFirm}
              onChange={(e) => setSelectedFirm(e.target.value)}
            >
              <option value="">Sve</option>
              {firms.filter(firm => firm.id !== 'default').map((firm) => (
                <option key={firm.id} value={firm.id}>
                  {firm.name}
                </option>
              ))}
            </select>

            <label>Godina:</label>
            <select
              value={selectedYear}
              onChange={(e) => setSelectedYear(parseInt(e.target.value))}
            >
              {[2022, 2023, 2024, 2025].map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>

            <label>Mjesec:</label>
            <select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
            >
              {[...Array(12)].map((_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>

            {/* Gumb za upravljanje firmama */}
            <button onClick={() => setIsFirmManagerOpen(true)} className="manage-firms-button">
              Upravljaj firmama
            </button>

            {/* Gumb za upravljanje uploadanim danima */}
            <button onClick={() => setIsUploadDaysModalOpen(true)} className="manage-dates-button">
              Upravljaj uploadanim danima
            </button>

            {/* Gumb za upravljanje metrickim postavkama */}
            <button onClick={() => setIsMetrickePostavkeModalOpen(true)} className="manage-settings-button">
              Upravljaj metrickim postavkama
            </button>
          </div>

          {/* Modal za upravljanje firmama */}
          <Modal
            isOpen={isFirmManagerOpen}
            onRequestClose={() => setIsFirmManagerOpen(false)}
            contentLabel="Upravljanje Firmama"
            className="modal"
            overlayClassName="overlay"
          >
            <FirmManager 
              firms={firms} 
              setFirms={setFirms} 
              moveUsersToDefaultFirm={moveUsersToDefaultFirm}
            />
            <button onClick={() => setIsFirmManagerOpen(false)} className="close-modal-button">
              Zatvori
            </button>
          </Modal>

          {/* Modal za upravljanje uploadanim danima */}
          <Modal
            isOpen={isUploadDaysModalOpen}
            onRequestClose={() => setIsUploadDaysModalOpen(false)}
            contentLabel="Upravljanje Uploadanim Danima"
            className="modal"
            overlayClassName="overlay"
          >
            <div className="metrics-modal-header">
              <h2>Uploadani Dani za {selectedMonth}/{selectedYear}</h2>
              <button onClick={() => setIsUploadDaysModalOpen(false)} className="metrics-modal-close-button">
                Zatvori
              </button>
            </div>

            {/* Filtriranje unutar modala po firmama */}
            <div className="modal-filter">
              <label>Filter po Firmi:</label>
              <select
                value={filterFirm}
                onChange={(e) => setFilterFirm(e.target.value)}
              >
                <option value="">Sve</option>
                {firms.filter(firm => firm.id !== 'default').map((firm) => (
                  <option key={firm.id} value={firm.id}>
                    {firm.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Prikaz liste uploadanih dana */}
            <div className="uploaded-days-list">
              {uploadedDays.length === 0 ? (
                <p>Nema uploadanih dana za ovaj mjesec.</p>
              ) : (
                <ul>
                  {uploadedDays
                    .filter((dayObj) => {
                      if (filterFirm === '') return true;
                      return dayObj.firmId === filterFirm;
                    })
                    .map((dayObj) => (
                      <li key={dayObj.id}>
                        <span>
                          {firms.find(firm => firm.id === dayObj.firmId)?.name || 'Default'} - Dan {dayObj.day} ({dayObj.filename})
                        </span>
                        <button
                          className="delete-day-button"
                          onClick={() => deleteUploadedDay(dayObj.day, dayObj.firmId)}
                        >
                          IZBRIŠI
                        </button>
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </Modal>

          {/* Modal za metricke postavke */}
          <Modal
            isOpen={isMetrickePostavkeModalOpen}
            onRequestClose={() => setIsMetrickePostavkeModalOpen(false)}
            contentLabel="Metricke Postavke"
            className="modal"
            overlayClassName="overlay"
          >
            <div className="metrics-modal-header">
              <h2>Metricke Postavke</h2>
              <button onClick={() => setIsMetrickePostavkeModalOpen(false)} className="metrics-modal-close-button">
                Zatvori
              </button>
            </div>

            {/* Forma za metricke postavke */}
            <form onSubmit={handleSaveMetrickePostavke} className="metricke-settings-form">
              <div className="form-group">
                <label>Min dnevno stranci:</label>
                <input
                  type="number"
                  value={minDailyStranci}
                  onChange={(e) => setMinDailyStranci(e.target.value)}
                  required
                  min="0"
                />
              </div>

              <div className="form-group">
                <label>Min dnevno Hrvati:</label>
                <input
                  type="number"
                  value={minDailyHrvati}
                  onChange={(e) => setMinDailyHrvati(e.target.value)}
                  required
                  min="0"
                />
              </div>

              <div className="form-group">
                <label>Min mjesečno stranci:</label>
                <input
                  type="number"
                  value={minMonthlyStranci}
                  onChange={(e) => setMinMonthlyStranci(e.target.value)}
                  required
                  min="0"
                />
              </div>

              <div className="form-group">
                <label>Min mjesečno Hrvati:</label>
                <input
                  type="number"
                  value={minMonthlyHrvati}
                  onChange={(e) => setMinMonthlyHrvati(e.target.value)}
                  required
                  min="0"
                />
              </div>

              <button type="submit" className="save-settings-button">
                Spremi
              </button>
            </form>
          </Modal>

          {/* Prikaz tablice s metrima */}
          <div className="metrics-table-wrapper">
            <MetricsTable
              people={people}
              dailyMetricsData={dailyMetricsData}
              selectedNationality={selectedNationality}
              selectedFirm={selectedFirm}
              selectedYear={selectedYear}
              selectedMonth={selectedMonth}
              refreshData={refreshData}
              firms={firms}
              updateDailyMetrics={updateDailyMetrics} // Prosljeđujemo funkciju
              metrickePostavke={{
                minDailyStranci: Number(minDailyStranci),
                minDailyHrvati: Number(minDailyHrvati),
                minMonthlyStranci: Number(minMonthlyStranci),
                minMonthlyHrvati: Number(minMonthlyHrvati),
              }}
            />
          </div>
        </div>
      ) : (
        <Login />
      )}
    </div>
  );
}

export default Metrike;