// App.jsx

import React, { useState, useEffect, useContext } from 'react';

import PersonForm from './components/PersonForm';
import PersonTable from './components/PersonTable';
import PaymentTable from './components/PaymentTable';
import FirmManager from './components/FirmManager'; // Importiramo FirmManager

import { AuthContext } from './config/AuthProvider';
import Login from './authPage/Login';

import { db, auth } from './config/firebase'; // Importamo db i auth
import { collection, getDocs, query, where, addDoc, deleteDoc, doc, setDoc, updateDoc } from 'firebase/firestore';

import Modal from 'react-modal'; // Importiramo Modal iz react-modal

import './App.css'; // Importamo CSS

function App() {
  // Postojeća stanja
  const [people, setPeople] = useState([]);
  const [earningsData, setEarningsData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedNationality, setSelectedNationality] = useState('Sve'); // Promijenjeno na 'Sve' kao default
  const [selectedPeriod, setSelectedPeriod] = useState(1); // Dodali smo selectedPeriod
  const [userPeriodData, setUserPeriodData] = useState({});
  const [uploadedWeeks, setUploadedWeeks] = useState([]); // Stanje za praćenje učitanih tjedana

  // Novo stanje za firme
  const [firms, setFirms] = useState([]);

  // Novo stanje za odabranu firmu
  const [selectedFirm, setSelectedFirm] = useState(''); // Početna vrijednost je '' što znači "Sve"

  const { currentUser } = useContext(AuthContext);

  const EARNINGS_COLUMN_NAME = 'total to be transfered to glover';

  // Stanje za upravljanje vidljivošću FirmManager modala
  const [isFirmManagerOpen, setIsFirmManagerOpen] = useState(false);

  // Funkcija za dohvaćanje svih firmi
  const fetchFirms = async () => {
    try {
      const firmsCollection = collection(db, 'firms');
      const q = query(firmsCollection);
      const querySnapshot = await getDocs(q);
      const firmsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setFirms(firmsData);
    } catch (error) {
      console.error('Greška pri dohvaćanju firmi:', error);
    }
  };

  // Funkcija za provjeru i kreiranje Default firme ako ne postoji
  const ensureDefaultFirm = async () => {
    try {
      const defaultFirmRef = doc(db, 'firms', 'default');
      const defaultFirmSnap = await getDocs(query(collection(db, 'firms'), where('__name__', '==', 'default')));
      if (defaultFirmSnap.empty) {
        await setDoc(defaultFirmRef, {
          name: 'Default',
          createdAt: new Date()
        });
        console.log('Zadana firma kreirana.');
        setFirms(prevFirms => [...prevFirms, { id: 'default', name: 'Default', createdAt: new Date() }]);
      } else {
        const defaultFirm = defaultFirmSnap.docs[0].data();
        setFirms(prevFirms => [...prevFirms, { id: 'default', ...defaultFirm }]);
        console.log('Zadana firma već postoji.');
      }
    } catch (error) {
      console.error('Greška pri provjeri/zadavanju firme:', error);
    }
  };

  // Funkcija za dohvaćanje podataka po periodu za sve korisnike
  const fetchUserPeriodData = async () => {
    try {
      const qUserPeriod = query(
        collection(db, 'userPeriodData'),
        where('year', '==', selectedYear),
        where('month', '==', selectedMonth),
        where('period', '==', selectedPeriod)
      );
      const querySnapshot = await getDocs(qUserPeriod);
      const data = {};
      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        data[docData.userId] = { id: doc.id, ...docData };
      });
      setUserPeriodData(data);
    } catch (e) {
      console.error('Greška pri dohvaćanju user period data:', e);
    }
  };

  // Funkcija za dohvaćanje učitanih tjedana iz "uploadedWeeks" kolekcije
  const fetchUploadedWeeks = async () => {
    try {
      let qUploadedWeeks = query(
        collection(db, 'uploadedWeeks'),
        where('year', '==', selectedYear),
        where('month', '==', selectedMonth)
      );

      // Ako je odabrana firma, dodajemo filter za firmId
      if (selectedFirm !== '') {
        qUploadedWeeks = query(qUploadedWeeks, where('firmId', '==', selectedFirm));
      }

      const querySnapshot = await getDocs(qUploadedWeeks);
      const weeks = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        week: doc.data().week,
        filename: doc.data().filename,
        year: doc.data().year,
        month: doc.data().month,
        firmId: doc.data().firmId // Dodajemo firmId
      }));
      setUploadedWeeks(weeks);
    } catch (e) {
      console.error('Greška pri dohvaćanju uploadedWeeks:', e);
    }
  };

  // Dohvaćanje podataka po periodu kad se promijene godina, mjesec, period ili firma
  useEffect(() => {
    fetchUserPeriodData();
    fetchUploadedWeeks();
  }, [selectedYear, selectedMonth, selectedPeriod, selectedFirm]);

  const fetchUsers = async () => {
    try {
      const qUsers = query(collection(db, 'users'));
      const querySnapshot = await getDocs(qUsers);
      const users = querySnapshot.docs.map((doc) => {
        const data = doc.data();
  
        return {
          id: doc.id,
          name: data.name || '',
          nationality: data.nationality || 'Hrvat', // Default to 'Hrvat' if not specified
          tekuciRacun: data.tekuciRacun || '',
          zasticeniRacun: data.zasticeniRacun || '',
          riderID: data.riderID || '',
          firmId: data.firmId || 'default',
          appKnjig: data.appKnjig || 0,
          doprinosi: data.doprinosi || 0,
          fee: data.fee || 0,
          renta: data.renta || 0,
          rentSmjestaj: data.rentSmjestaj || 0,
          oprema: data.oprema || 0,
          akontacija: data.akontacija || 0,
          gorivo: data.gorivo || 0,
          ostalo: data.ostalo || 0,
          bonus: data.bonus || 0,
          // New fields for 'Stranac' (Foreigners)
          tarifa: data.tarifa || 0,
          depozit: data.depozit || 0,
          struja: data.struja || 0,
          glovoTax: data.glovoTax || 0,
        };
      });
      setPeople(users);
      console.log('Dohvaćeni korisnici:', users);
    } catch (e) {
      console.error('Greška pri dohvaćanju korisnika:', e);
    }
  };
  

  // Funkcija za dohvaćanje zarada iz Firestorea
  const fetchEarnings = async () => {
    try {
      let qEarnings = query(
        collection(db, 'earnings'),
        where('year', '==', selectedYear),
        where('month', '==', selectedMonth)
      );

      // Ako je Hrvat, dodajemo filter za period
      if (selectedNationality === 'Hrvat') {
        qEarnings = query(qEarnings, where('period', '==', selectedPeriod));
      }

      const querySnapshot = await getDocs(qEarnings);
      const earnings = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEarningsData(earnings);
      console.log('Dohvaćene zarade:', earnings);
    } catch (e) {
      console.error('Greška pri dohvaćanju zarada:', e);
    }
  };

  // Dohvaćanje firmi i osiguravanje da Default firma postoji kada je korisnik prijavljen
  useEffect(() => {
    if (currentUser) {
      ensureDefaultFirm();
      fetchFirms();
    }
  }, [currentUser]);

  // Dohvaćanje korisnika kada je korisnik prijavljen
  useEffect(() => {
    if (currentUser) {
      fetchUsers();
    }
  }, [currentUser]);

  // Dohvaćanje zarada kada je korisnik prijavljen ili se promijene filteri
  useEffect(() => {
    if (currentUser) {
      fetchEarnings();
      fetchUploadedWeeks(); // Osiguravamo da su učitani tjedni ažurirani
    }
  }, [currentUser, selectedYear, selectedMonth, selectedPeriod, selectedNationality, selectedFirm]);

  // Funkcija za dodavanje nove osobe
  const addPerson = async (person) => {
    // Provjera jedinstvenosti Rider ID-a
    const riderIDQuery = query(
      collection(db, 'users'),
      where('riderID', '==', person.riderID)
    );
    const riderIDSnapshot = await getDocs(riderIDQuery);
    if (!riderIDSnapshot.empty) {
      alert('Rider ID već postoji. Molimo unesite jedinstveni Rider ID.');
      return;
    }

    // Provjera da li je odabrana firma validna
    const selectedFirmObj = firms.find(firm => firm.id === person.firmId);
    if (!selectedFirmObj) {
      alert('Odabrana firma ne postoji.');
      return;
    }

    // Spremanje u Firestore
    try {
      const docRef = await addDoc(collection(db, 'users'), person);
      console.log('Korisnik dodan s ID-om:', docRef.id);
      // Ažuriranje lokalnog stanja
      setPeople([...people, { id: docRef.id, ...person }]);
      alert('Korisnik uspješno dodan!');
    } catch (e) {
      console.error('Greška pri dodavanju korisnika:', e);
      alert('Došlo je do greške prilikom dodavanja korisnika. Pokušajte ponovno.');
    }
  };

  // Funkcija za ažuriranje zarada
  const updateEarnings = async (week, data, filename) => {
    console.log('Excel Podaci:', data);
    console.log('Trenutno stanje korisnika:', people);
    console.log('Tjedan:', week);
  
    const year = selectedYear;
    const month = selectedMonth;
    const firmId = selectedFirm; // Koristimo odabranu firmu
    const uniqueWeekId = `${year}-${month}-${week}-${firmId}`; // Uključujemo firmId u ID
  
    // Validacija: Provjera je li firma odabrana
    if (firmId === '') {
      alert('Molimo odaberite firmu prije upload-a.');
      return;
    }
  
    // Provjera da li je tjedan već uvezen za tu firmu
    const existingWeek = uploadedWeeks.find(
      (w) => w.week === week && w.year === year && w.month === month && w.firmId === firmId
    );
  
    if (existingWeek) {
      alert(`Tjedan ${week} za ${month}/${year} i firmu ${getFirmName(firmId)} je već uvezen.`);
      return; // Prekidamo proces ako je tjedan već uvezen za tu firmu
    }
  
    // Skup korisnika kojima su ažurirane zarade
    const updatedUserIds = new Set();
  
    for (const item of data) {
      // Provjera i normalizacija Rider ID-a
      const riderIDRaw = item.riderid;
      console.log('Originalni Rider ID iz Excela:', riderIDRaw);
  
      let riderID = null;
      if (typeof riderIDRaw === 'string') {
        riderID = riderIDRaw.trim().toLowerCase();
      } else if (riderIDRaw != null) {
        // Ako nije string, pokušaj ga pretvoriti u string
        riderID = String(riderIDRaw).trim().toLowerCase();
      }
  
      if (!riderID) {
        console.log('Rider ID nije prisutan ili je neispravan u Excel podacima za unos.');
        continue;
      }
  
      // Pronalazak osobe s odgovarajućim Rider ID-om
      const person = people.find(
        (p) => p.riderID && p.riderID.trim().toLowerCase() === riderID
      );
  
      if (person) {
        const earningsKey = EARNINGS_COLUMN_NAME.toLowerCase().replace(/\s+/g, '');
        const earningsValue = item[earningsKey]; // 'totaltobettransferedtoglover'
        console.log(`Zarada za ${person.name}:`, earningsValue);
  
        if (earningsValue !== undefined) {
          let period;
  
          if (person.nationality === 'Hrvat') {
            if (week >= 1 && week <= 2) {
              period = 1;
            } else if (week >= 3 && week <= 5) {
              period = 2;
            } else {
              console.error(`Nevažeći tjedan ${week} za Hrvata`);
              continue; // Preskačemo ako je tjedan izvan očekivanog raspona
            }
          } else {
            // Za strance period je uvijek 1
            period = 1;
          }
  
          // Spremanje zarade u Firestore
          try {
            await addDoc(collection(db, 'earnings'), {
              userId: person.id,
              year,
              month,
              period,
              week,
              amount: earningsValue,
              firmId, // Dodajemo firmId u zaradu
            });
            console.log(`Zarada spremljena za ${person.name} za tjedan ${week}`);
          } catch (e) {
            console.error('Greška pri spremanju zarade:', e);
          }
  
          // Dodavanje korisnika u skup ažuriranih korisnika
          updatedUserIds.add(person.id);
  
        } else {
          console.log(
            `Zarada kolone "${EARNINGS_COLUMN_NAME}" nije pronađena u earningData`
          );
        }
      } else {
        console.log(`Nije pronađen korisnik za Rider ID: ${riderID}`);
      }
    }
  
    // Nakon što su svi podaci procesirani, dodajemo tjedan u 'uploadedWeeks'
    try {
      await setDoc(doc(db, 'uploadedWeeks', uniqueWeekId), {
        week,
        year,
        month,
        filename,
        firmId, // Dodajemo firmId
      });
      setUploadedWeeks([...uploadedWeeks, { id: uniqueWeekId, week, filename, year, month, firmId }]);
      alert(`Tjedan ${week} za ${month}/${year} i firmu ${getFirmName(firmId)} je uspješno uvezen.`);
    } catch (e) {
      console.error('Greška pri dodavanju u uploadedWeeks:', e);
      alert('Došlo je do greške prilikom označavanja tjedna kao učitanog.');
    }
  
    // **Nova logika za kreiranje userPeriodData s defaultnim vrijednostima**
    try {
      for (const userId of updatedUserIds) {
        const person = people.find((p) => p.id === userId);
        if (!person) continue;
  
        let period;
  
        if (person.nationality === 'Hrvat') {
          if (week >= 1 && week <= 2) {
            period = 1;
          } else if (week >= 3 && week <= 5) {
            period = 2;
          } else {
            console.error(`Nevažeći tjedan ${week} za Hrvata`);
            continue; // Preskačemo ako je tjedan izvan očekivanog raspona
          }
        } else {
          // Za strance period je uvijek 1
          period = 1;
        }
  
        // Provjera postoji li već userPeriodData za ovog korisnika u ovom periodu
        const userPeriodQuery = query(
          collection(db, 'userPeriodData'),
          where('userId', '==', person.id),
          where('year', '==', year),
          where('month', '==', month),
          where('period', '==', period)
        );
        const userPeriodSnapshot = await getDocs(userPeriodQuery);
  
        if (userPeriodSnapshot.empty) {
          // Kreiramo userPeriodData s defaultnim vrijednostima iz person
          const newUserPeriodData = {
            userId: person.id,
            year,
            month,
            period,
            fee: person.fee || 0,
            appKnjig: person.appKnjig || 0,
            doprinosi: person.doprinosi || 0,
            renta: person.renta || 0,
            rentSmjestaj: person.rentSmjestaj || 0,
            oprema: person.oprema || 0,
            akontacija: person.akontacija || 0,
            gorivo: person.gorivo || 0,
            ostalo: person.ostalo || 0,
            bonus: person.bonus || 0,
            napomena: '',
            zaradaTotal: 0,
            feeAmount: 0,
            total1: 0,
            total2: 0,
          };
        
          if (person.nationality === 'Stranac') {
            newUserPeriodData.tarifa = person.tarifa || 0;
            newUserPeriodData.depozit = person.depozit || 0;
            newUserPeriodData.struja = person.struja || 0;
            newUserPeriodData.glovoTax = person.glovoTax || 0;
          }
        
          await addDoc(collection(db, 'userPeriodData'), newUserPeriodData);
          console.log(`Kreiran userPeriodData za korisnika ${person.name} za period ${period}/${month}/${year}`);
        }
      }
    } catch (e) {
      console.error('Greška pri kreiranju userPeriodData:', e);
    }
  
    // Nakon spremanja, osvježavamo podatke
    refreshData();
  };

  // Funkcija za dohvaćanje imena firme na temelju firmId
  const getFirmName = (firmId) => {
    const firm = firms.find(firm => firm.id === firmId);
    return firm ? firm.name : 'Default';
  };

  // Funkcija za brisanje zarada za određeni tjedan i firmu
  const deleteEarningsForWeek = async (week, firmId) => { // Izmijenjeno da prima firmId
    if (!window.confirm(`Jeste li sigurni da želite obrisati sve zarade za tjedan ${week} i firmu ${getFirmName(firmId)}?`)) {
      return;
    }

    try {
      // Dohvaćanje učitanih tjedana koji odgovaraju odabranom tjednu, godini, mjesecu i firmi
      const qWeeks = query(
        collection(db, 'uploadedWeeks'),
        where('week', '==', week),
        where('year', '==', selectedYear),
        where('month', '==', selectedMonth),
        where('firmId', '==', firmId) // Dodajemo filter za firmId
      );

      const weeksSnapshot = await getDocs(qWeeks);

      if (weeksSnapshot.empty) {
        alert('Nema učitanih tjedana za ovaj kriterij.');
        return;
      }

      // Brisanje zarada i uploadedWeeks za svaki pronađeni tjedan
      for (const weekDoc of weeksSnapshot.docs) {
        const weekData = weekDoc.data();
        const firmId = weekData.firmId;

        // Brisanje zarada iz "earnings" kolekcije za taj tjedan i firmu
        const qEarnings = query(
          collection(db, 'earnings'),
          where('year', '==', selectedYear),
          where('month', '==', selectedMonth),
          where('week', '==', week),
          where('firmId', '==', firmId)
        );
        const earningsSnapshot = await getDocs(qEarnings);

        const deletePromises = earningsSnapshot.docs.map((docSnap) =>
          deleteDoc(doc(db, 'earnings', docSnap.id))
        );

        await Promise.all(deletePromises);
        console.log(`Sve zarade za tjedan ${week} i firmu ${getFirmName(firmId)} su izbrisane.`);

        // Brisanje iz "uploadedWeeks" kolekcije
        await deleteDoc(doc(db, 'uploadedWeeks', weekDoc.id));
        console.log(`Tjedan ${week} za firmu ${getFirmName(firmId)} je izbrisan iz uploadedWeeks.`);

        // Ažuriranje stanja uploadedWeeks
        setUploadedWeeks(uploadedWeeks.filter((w) => w.id !== weekDoc.id));
      }

      // Ažuriramo zarade nakon brisanja
      refreshData();
    } catch (e) {
      console.error(`Greška pri brisanju zarada za tjedan ${week} i firmu ${firmId}:`, e);
    }
  };

  // Funkcija za brisanje osobe
  const handleDeletePerson = (personId) => {
    // Uklanjanje osobe iz lokalnog stanja
    setPeople(people.filter((person) => person.id !== personId));
    // Ažuriranje zarada i učitanih tjedana
    refreshData();
  };

  // Funkcija za ažuriranje osobe u lokalnom stanju
  const handleUpdatePerson = (updatedPerson) => {
    setPeople((prevPeople) =>
      prevPeople.map((person) =>
        person.id === updatedPerson.id ? updatedPerson : person
      )
    );
  };

  // Funkcija za osvježavanje podataka
  const refreshData = () => {
    fetchEarnings();
    fetchUserPeriodData();
    fetchUploadedWeeks();
    fetchFirms(); // Osvježava i firme, u slučaju promjena
    fetchUsers(); // Osvježava i korisnike
  };

  // Funkcija za ažuriranje napomene
  const updateNapomena = async (userId, year, month, period, newNapomena) => {
    try {
      const napomenaQuery = query(
        collection(db, 'userPeriodData'),
        where('userId', '==', userId),
        where('year', '==', year),
        where('month', '==', month),
        where('period', '==', period)
      );
      const napomenaSnapshot = await getDocs(napomenaQuery);

      if (!napomenaSnapshot.empty) {
        const docRef = napomenaSnapshot.docs[0].ref;
        await updateDoc(docRef, {
          napomena: newNapomena,
        });
      } else {
        // Ako dokument ne postoji, kreiraj novi
        await addDoc(collection(db, 'userPeriodData'), {
          userId,
          year,
          month,
          period,
          napomena: newNapomena,
          // Ostali potrebni podaci mogu biti dodani ovdje ako je potrebno
        });
      }

      // Osvježavanje podataka nakon ažuriranja
      refreshData();
    } catch (e) {
      console.error('Greška pri ažuriranju napomene:', e);
      alert('Došlo je do greške prilikom ažuriranja napomene. Pokušajte ponovno.');
    }
  };

  // Funkcija za prebacivanje korisnika u Default firmu prilikom brisanja firme
  const moveUsersToDefaultFirm = async (firmIdToDelete) => {
    try {
      // Dohvaćanje svih korisnika koji pripadaju obrisanoj firmi
      const qUsers = query(
        collection(db, 'users'),
        where('firmId', '==', firmIdToDelete)
      );
      const usersSnapshot = await getDocs(qUsers);

      const updatePromises = usersSnapshot.docs.map((docSnap) =>
        updateDoc(doc(db, 'users', docSnap.id), { firmId: 'default' })
      );

      await Promise.all(updatePromises);
      console.log(`Sve osobe iz firme ${firmIdToDelete} prebačene u Default firmu.`);
      // Osvježavanje podataka nakon prebacivanja
      refreshData();
    } catch (error) {
      console.error('Greška pri prebacivanju korisnika u Default firmu:', error);
      alert('Došlo je do greške prilikom prebacivanja korisnika u Default firmu.');
    }
  };

  return (
    <div>
      <h1>OBRAČUN</h1>
      {currentUser ? (
        <div>
          {/* Filtri za nacionalnost, godinu, mjesec i firmu */}
          <div className="filter-form">
            {/* Prosljeđujemo listu firmi PersonForm */}
            <PersonForm onAddPerson={addPerson} firms={firms} />

            {/* Gumb za upravljanje firmama */}
            <button onClick={() => setIsFirmManagerOpen(true)} className="manage-firms-button">
              Upravljaj firmama
            </button>

            <label>Nacionalnost:</label>
            <select
              value={selectedNationality}
              onChange={(e) => setSelectedNationality(e.target.value)}
            >
              <option value="Sve">Sve</option> {/* Opcija za sve nacionalnosti */}
              <option value="Hrvat">Hrvat</option>
              <option value="Stranac">Stranac</option>
            </select>

            <label>Firma:</label>
            <select
              value={selectedFirm}
              onChange={(e) => setSelectedFirm(e.target.value)}
            >
              <option value="">Sve</option> {/* Opcija za sve firme */}
              {firms.filter(firm => firm.id !== 'default').map((firm) => (
                <option key={firm.id} value={firm.id}>
                  {firm.name}
                </option>
              ))}
            </select>

            <label>Godina:</label>
            <select
              value={selectedYear}
              onChange={(e) => setSelectedYear(parseInt(e.target.value))}
            >
              {[2022, 2023, 2024].map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>

            <label>Mjesec:</label>
            <select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
            >
              {[...Array(12)].map((_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>

            {/* Ako je nacionalnost Hrvat, prikazujemo filter za period */}
            {selectedNationality === 'Hrvat' && (
              <>
                <label>Period:</label>
                <select
                  value={selectedPeriod}
                  onChange={(e) => setSelectedPeriod(parseInt(e.target.value))}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                </select>
              </>
            )}
          </div>

          {/* Modal za upravljanje firmama */}
          <Modal
            isOpen={isFirmManagerOpen}
            onRequestClose={() => setIsFirmManagerOpen(false)}
            contentLabel="Upravljanje Firmama"
            className="modal"
            overlayClassName="overlay"
          >
            <FirmManager 
              firms={firms} 
              setFirms={setFirms} 
              moveUsersToDefaultFirm={moveUsersToDefaultFirm}
            />
            <button onClick={() => setIsFirmManagerOpen(false)} className="close-modal-button">
              Zatvori
            </button>
          </Modal>

          {/* Prikaz učitanih tjedana */}
          <div className="uploaded-weeks">
            <h2>Učitani tjedni</h2>
            {uploadedWeeks.length === 0 ? (
              <p>Nema učitanih tjedana.</p>
            ) : (
              <ul>
                {uploadedWeeks.map((weekObj) => (
                  <li key={weekObj.id}>
                    <span>
                      {selectedFirm !== '' ? `Firma: ${getFirmName(weekObj.firmId)} - ` : ''}
                      Tjedan {weekObj.week} ({weekObj.filename})
                    </span>
                    <button
                      className="delete-week-button"
                      onClick={() => deleteEarningsForWeek(weekObj.week, weekObj.firmId)} // Prosljeđujemo firmId
                    >
                      IZBRIŠI
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Omotavanje tablice u scrollable div */}
          <div className="table-container">
            <PersonTable
              people={people}
              earningsData={earningsData}
              userPeriodData={userPeriodData}
              selectedNationality={selectedNationality}
              updateEarnings={updateEarnings}
              selectedPeriod={selectedPeriod}
              selectedYear={selectedYear}
              selectedMonth={selectedMonth}
              onDeletePerson={handleDeletePerson}
              onUpdatePerson={handleUpdatePerson}
              refreshData={refreshData}
              firms={firms} 
              selectedFirm={selectedFirm} 
            />
          </div>
          <br />
          <div className="table-container">
            <PaymentTable
              people={people}
              earningsData={earningsData}
              userPeriodData={userPeriodData}
              selectedYear={selectedYear}
              selectedMonth={selectedMonth}
              selectedPeriod={selectedPeriod}
              selectedNationality={selectedNationality}
              selectedFirm={selectedFirm} 
              firms={firms}
              updateNapomena={updateNapomena} 
            />
          </div>
        </div>
      ) : (
        <Login />
      )}
    </div>
  );
}

export default App;
