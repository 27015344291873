import React, { useMemo } from 'react';
import MetricsRow from './MetricsRow';
import ExcelUploader from './ExcelUploader';
import '../css/MetricsTable.css'; // Importiramo odgovarajuće stilove

function MetricsTable({
  people = [],
  dailyMetricsData = [],
  selectedNationality,
  selectedFirm,
  selectedYear,
  selectedMonth,
  refreshData,
  firms = [],
  updateDailyMetrics, // Funkcija za ažuriranje metrika
  metrickePostavke = {}, // Objekt s postavkama metrika
}) {
  const daysInMonth = 31; // Assume all months have 31 days for display purposes
  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

  const filteredPeople = useMemo(() => people.filter(person => (
    (selectedNationality === 'Sve' || person.nationality === selectedNationality) &&
    (selectedFirm === '' || person.firmId === selectedFirm)
  )), [people, selectedNationality, selectedFirm]);

  // Calculate total and daily sums
  const { totalSum, dailySums } = useMemo(() => {
    let total = 0;
    const dailyTotals = Array(daysInMonth).fill(0);
    filteredPeople.forEach(person => {
      dailyMetricsData.filter(metric => metric.userId === person.id).forEach(metric => {
        const dayIndex = metric.day - 1;
        const amount = parseFloat(metric.amount) || 0;
        dailyTotals[dayIndex] += amount;
        total += amount;
      });
    });
    return { totalSum: total, dailySums: dailyTotals };
  }, [filteredPeople, dailyMetricsData]);

  return (
    <div className="metrics-table-scrollable">
      <table className="metrics-table">
        <thead>
          <tr>
            <th>Ime</th>
            <th>Rider ID</th>
            <th>Firma</th>
            <th>Nacionalnost</th>
            {days.map(day => (
              <th key={day}>
                <ExcelUploader
                  onDataParsed={(data, filename) => {
                    updateDailyMetrics(day, data, filename);
                  }}
                >
                  Dan {day}
                </ExcelUploader>
              </th>
            ))}
            <th>Suma</th>
          </tr>
        </thead>
        <tbody>
          {filteredPeople.map(person => (
            <MetricsRow
              key={person.id}
              person={person}
              dailyMetricsData={dailyMetricsData.filter(metric => metric.userId === person.id)}
              selectedYear={selectedYear}
              selectedMonth={selectedMonth}
              refreshData={refreshData}
              firms={firms}
              metrickePostavke={metrickePostavke}
            />
          ))}
          <tr className="summary-row">
            <td><strong>Suma</strong></td>
            <td></td>
            <td></td>
            <td></td>
            {dailySums.map((sum, index) => (
              <td key={index}><strong>{sum.toFixed(2)}</strong></td>
            ))}
            <td><strong>{totalSum.toFixed(2)}</strong></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default MetricsTable;
