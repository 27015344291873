// src/components/MetricsRow.jsx

import React, { useState, useMemo } from 'react';
import { db } from '../config/firebase';
import { collection, addDoc, updateDoc, deleteDoc, query, where, getDocs, doc } from 'firebase/firestore';
import Modal from 'react-modal';
import { FaEdit, FaTrash } from 'react-icons/fa';
import '../css/MetricsRow.css'; // Kreirajte odgovarajuće stilove

function MetricsRow({
  person,
  dailyMetricsData = [],
  selectedYear,
  selectedMonth,
  refreshData,
  firms = [],
  metrickePostavke = {}, // Primamo postavke metrika
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDay, setCurrentDay] = useState(null);
  const [dailyAmount, setDailyAmount] = useState(0);

  // Funkcija za otvaranje modala za određeni dan
  const openModal = (day) => {
    const metric = dailyMetricsData.find(m => m.day === day);
    setCurrentDay(day);
    setDailyAmount(metric ? parseFloat(metric.amount) : 0);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentDay(null);
    setDailyAmount(0);
  };

  // Funkcija za spremanje metrike
  const handleSave = async () => {
    try {
      const metricsCollection = collection(db, 'dailyMetrics');
      const q = query(
        metricsCollection,
        where('userId', '==', person.id),
        where('year', '==', selectedYear),
        where('month', '==', selectedMonth),
        where('day', '==', currentDay)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Ažuriranje postojeće metrike
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, { amount: dailyAmount });
      } else {
        // Kreiranje nove metrike
        await addDoc(metricsCollection, {
          userId: person.id,
          year: selectedYear,
          month: selectedMonth,
          day: currentDay,
          amount: dailyAmount,
        });
      }

      alert('Metrika uspješno spremljena!');
      closeModal();
      refreshData();
    } catch (e) {
      console.error('Greška pri spremanju metrike:', e);
      alert('Došlo je do greške prilikom spremanja metrike. Pokušajte ponovno.');
    }
  };

  // Funkcija za brisanje metrike
  const handleDelete = async () => {
    if (window.confirm(`Jeste li sigurni da želite obrisati metriku za dan ${currentDay}?`)) {
      try {
        const metricsCollection = collection(db, 'dailyMetrics');
        const q = query(
          metricsCollection,
          where('userId', '==', person.id),
          where('year', '==', selectedYear),
          where('month', '==', selectedMonth),
          where('day', '==', currentDay)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const docRef = querySnapshot.docs[0].ref;
          await deleteDoc(docRef);
          alert('Metrika uspješno izbrisana!');
          closeModal();
          refreshData();
        }
      } catch (e) {
        console.error('Greška pri brisanju metrike:', e);
        alert('Došlo je do greške prilikom brisanja metrike. Pokušajte ponovno.');
      }
    }
  };

  // Funkcija za dohvaćanje imena firme na temelju firmId
  const getFirmName = (firmId) => {
    const firm = firms.find(firm => firm.id === firmId);
    return firm ? firm.name : 'N/A';
  };

  // Funkcija za provjeru i određivanje klase ćelije za dnevne metrike
  const getCellClass = (amount) => {
    if (person.nationality === 'Stranac') {
      if (amount < metrickePostavke.minDailyStranci) {
        return 'low-earning';
      }
    } else if (person.nationality === 'Hrvat') {
      if (amount < metrickePostavke.minDailyHrvati) {
        return 'low-earning';
      }
    }

    return '';
  };

  // Izračun mjesečne zarade za ovog korisnika
  const monthlySum = useMemo(() => {
    return dailyMetricsData.reduce((sum, m) => sum + (parseFloat(m.amount) || 0), 0);
  }, [dailyMetricsData]);

  // Funkcija za provjeru klase za sumarnu ćeliju
  const getSumCellClass = () => {
    if (person.nationality === 'Stranac') {
      if (monthlySum >= metrickePostavke.minMonthlyStranci) {
        return 'high-earning';
      } else if (monthlySum < metrickePostavke.minMonthlyStranci) {
        return 'low-earning';
      }
    } else if (person.nationality === 'Hrvat') {
      if (monthlySum >= metrickePostavke.minMonthlyHrvati) {
        return 'high-earning';
      } else if (monthlySum < metrickePostavke.minMonthlyHrvati) {
        return 'low-earning';
      }
    }

    return '';
  };

  return (
    <>
      <tr>
        <td>{person.name}</td>
        <td>{person.riderID}</td>
        <td>{getFirmName(person.firmId)}</td>
        <td>{person.nationality === 'Hrvat' ? 'H' : 'S'}</td>
        {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => {
          const metric = dailyMetricsData.find(m => m.day === day);
          const amount = metric ? parseFloat(metric.amount) : 0;
          const cellClass = metric ? getCellClass(amount) : '';

          return (
            <td key={day} className={cellClass}>
              {metric ? amount.toFixed(2) : '0.00'}
              
            </td>
          );
        })}
        <td className={getSumCellClass()}>
          <strong>{monthlySum.toFixed(2)}</strong>
        </td>
      </tr>

      {/* Modal za uređivanje metrike */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel={`Uredi metriku za dan ${currentDay}`}
        className="modal"
        overlayClassName="overlay"
      >
        <h2>Uredi metriku za dan {currentDay}</h2>
        <form className="edit-metric-form">
          <div className="form-group">
            <label>Rider ID:</label>
            <input type="text" value={person.riderID} disabled />
          </div>
          <div className="form-group">
            <label>Zarada (Challenges 1):</label>
            <input
              type="number"
              value={dailyAmount}
              onChange={(e) => setDailyAmount(parseFloat(e.target.value) || 0)}
              min="0"
              step="0.01"
            />
          </div>
          <div className="button-group">
            <button type="button" className="save-button" onClick={handleSave}>
              Spremi
            </button>
            <button type="button" className="delete-button" onClick={handleDelete}>
              <FaTrash /> Izbriši
            </button>
            <button type="button" className="cancel-button" onClick={closeModal}>
              Odustani
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default MetricsRow;