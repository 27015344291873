// PaymentTable.jsx

import React, { useState, useEffect } from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

function PaymentTable({
  people = [],
  earningsData = [],
  userPeriodData = {},
  selectedYear,
  selectedMonth,
  selectedPeriod,
  selectedNationality,
  selectedFirm,
  firms = [], // Added firms as a prop
}) {
  const [paymentData, setPaymentData] = useState([]);

  useEffect(() => {
    // Function to calculate payment data
    const calculatePaymentData = () => {
      let filteredPeople = [];
      console.log(selectedFirm)
      // Filter by nationality
      if (selectedNationality === 'Hrvat') {
        filteredPeople = people.filter((person) => person.nationality === 'Hrvat');
      } else if (selectedNationality === 'Stranac') {
        filteredPeople = people.filter((person) => person.nationality === 'Stranac');
      } else {
        filteredPeople = people;
      }

      // Filter by firm
      if (selectedFirm) {
        filteredPeople = filteredPeople.filter((person) => person.firmId === selectedFirm);
      }

      const data = filteredPeople.map((person) => {
        // Fetch period data for the person
        const periodData = userPeriodData[person.id] || {};

        // Fetch earnings for the person in the selected period
        const personEarnings = earningsData.filter(
          (earning) => earning.userId === person.id
        );

        // Calculate total earnings
        const zaradaTotal = personEarnings.reduce(
          (sum, earning) => sum + earning.amount,
          0
        );

        // Fetch fee, from periodData or person, or 0
        const fee = periodData.fee || person.fee || 0;

        // Calculate Total 1
        const total1 = zaradaTotal - (zaradaTotal * fee) / 100;

        // Other deductions
        const appKnjig = periodData.appKnjig || person.appKnjig || 0;
        const doprinosi = periodData.doprinosi || person.doprinosi || 0;
        const renta = periodData.renta || 0;
        const rentSmjestaj = periodData.rentSmjestaj || 0;
        const oprema = periodData.oprema || 0;
        const akontacija = periodData.akontacija || 0;
        const gorivo = periodData.gorivo || 0;
        const ostalo = periodData.ostalo || 0;
        const bonus = periodData.bonus || 0;
        const napomena = periodData.napomena || '';

        // Additional deductions for 'Stranac'
        const tarifa = periodData.tarifa || person.tarifa || 0;
        const depozit = periodData.depozit || person.depozit || 0;
        const struja = periodData.struja || person.struja || 0;
        const glovoTax = periodData.glovoTax || person.glovoTax || 0;

        // Calculate Total 2
        let total2 =
          total1 -
          appKnjig -
          doprinosi -
          renta -
          rentSmjestaj -
          oprema -
          akontacija -
          gorivo -
          ostalo -
          tarifa -
          depozit -
          struja -
          glovoTax +
          bonus;

        // If Total 2 is negative, set to 0
        if (total2 < 0) {
          total2 = 0;
        }

        return {
          name: person.name,
          totalForPayment: parseFloat(total2.toFixed(2)), // Ensure it's a number
          tekuciRacun: person.tekuciRacun || '',
          zasticeniRacun: person.zasticeniRacun || '',
          napomena: napomena,
        };
      });

      setPaymentData(data);
    };

    calculatePaymentData();
  }, [people, earningsData, userPeriodData, selectedPeriod, selectedNationality, selectedFirm]);

  // Function to get firm name
  const getFirmName = (firmId) => {
    const firm = firms.find(f => f.id === firmId);
    return firm ? firm.name : 'Sve';
  };

  // Function to convert column number to letter
  const getColumnLetter = (colNum) => {
    let temp;
    let letter = '';
    while (colNum > 0) {
      temp = (colNum - 1) % 26;
      letter = String.fromCharCode(temp + 65) + letter;
      colNum = (colNum - temp - 1) / 26;
    }
    return letter;
  };

  // Function to export to Excel with styling
  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Isplate');

    // Define columns
    worksheet.columns = [
      { header: 'Ime', key: 'name', width: 20 },
      { header: 'Total za isplatu', key: 'totalForPayment', width: 20 },
      { header: 'Tekući račun', key: 'tekuciRacun', width: 30 },
      { header: 'Zaštićeni račun', key: 'zasticeniRacun', width: 30 },
      { header: 'Napomene', key: 'napomena', width: 40 },
    ];

    // Add data rows
    paymentData.forEach((item) => {
      worksheet.addRow(item);
    });

    // Style header row
    worksheet.getRow(1).eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFF5F5DC' }, // Beige color
      };
      cell.font = { bold: true };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });

    // Style data cells
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber !== 1) {
        row.eachCell((cell) => {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          // Set number format for 'Total za isplatu' column
          if (cell._column && cell._column.key === 'totalForPayment') {
            cell.numFmt = '0.00';
          }
        });
      }
    });

    // Add sum row
    const totalRows = paymentData.length + 1; // +1 for header row
    const sumRowNumber = totalRows + 1;

    const sumRow = worksheet.addRow({
      name: 'UKUPNO',
      totalForPayment: '', // We'll set the formula below
      tekuciRacun: '',
      zasticeniRacun: '',
      napomena: '',
    });

    // Get the column number for 'totalForPayment'
    const totalForPaymentColumn = worksheet.getColumn('totalForPayment');
    const totalForPaymentColumnNumber = totalForPaymentColumn.number;
    const totalForPaymentColumnLetter = getColumnLetter(totalForPaymentColumnNumber);

    // Set the formula in the 'totalForPayment' cell
    sumRow.getCell('totalForPayment').value = {
      formula: `SUM(${totalForPaymentColumnLetter}2:${totalForPaymentColumnLetter}${totalRows})`,
    };

    // Style the sum row
    sumRow.eachCell((cell) => {
      cell.font = { bold: true };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'double' },
        right: { style: 'thin' },
      };
    });

    // Auto-fit column widths
    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2;
    });

    // Get firm name
    const firmName = getFirmName(selectedFirm);

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `Isplate_${firmName}_${selectedMonth}_${selectedYear}_P${selectedPeriod}.xlsx`
    );
  };

  return (
    <div className="payment-table-container">
      <h2>Konačna isplata</h2>
      <button onClick={exportToExcel} className="export-button">
        Izvezi u Excel
      </button>
      <table>
        <thead>
          <tr>
            <th>Ime</th>
            <th>Total za isplatu</th>
            <th>Tekući račun</th>
            <th>Zaštićeni račun</th>
            <th>Napomene</th>
          </tr>
        </thead>
        <tbody>
          {paymentData.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{item.totalForPayment.toFixed(2)}</td>
              <td>{item.tekuciRacun}</td>
              <td>{item.zasticeniRacun}</td>
              <td>{item.napomena}</td>
            </tr>
          ))}
          {/* Optionally, you can add the sum row in the table as well */}
          {/* <tr>
            <td><strong>UKUPNO</strong></td>
            <td><strong>{paymentData.reduce((sum, item) => sum + item.totalForPayment, 0).toFixed(2)}</strong></td>
            <td></td>
            <td></td>
            <td></td>
          </tr> */}
        </tbody>
      </table>
    </div>
  );
}

export default PaymentTable;
