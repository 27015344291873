// Login.jsx

import React, { useState, useContext } from 'react';
import { AuthContext } from '../config/AuthProvider';
import { useNavigate, Navigate } from 'react-router-dom';

function Login() {
  const { currentUser, login } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  if (currentUser) {
    return <Navigate to="/" />;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
    } catch (e) {
      console.error('Error during login:', e);
      alert('Pogreška prilikom prijave. Provjerite email i lozinku.');
    }
  };

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <h2>Prijava</h2>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <input
        type="password"
        placeholder="Lozinka"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <button type="submit">Prijava</button>
    </form>
  );
}

export default Login;